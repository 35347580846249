<template>
    <div class="product christophBrand">
        <!-- <Header/> -->
        <div  :class="{'jianbianbanner':iscaseBaner}" ref="caseBaner">
        <div class="spaceCustomization_img homeDecoration productkjdz" ref="homeDecoration">
            <div class="spaceCustomization">
                <img src='@/common/image/lightDecoration.jpg' alt="" >
            </div>
            <div class="homeDecorationMask" v-show="ishdMask" :style="{'height':hdmHeight}">
                <div class="homeDecorationCenten">
                    <transition-group name="hdcm">
                        <ul>
                            <li  v-for="(item, index) in kjdzData" :key="index" @click="kjdz(index)" :class="{'midl':midlIndex==index,'left':leftIndex == index,'right':rightIndex == index,'pwjstrone': midlIndex == index}">
                                <div class="zhoyao ">{{item.eName}}</div>
                                <p >{{item.eName}}</p>
                            </li>
                        </ul>
                    </transition-group>
                </div>
            </div>
        </div>

        </div>
        <div class="productnav "  :class="{'productnavTop':isproductnavTop,'productnavTopaa':isproductnavTopaa,'productnavTopbb':isproductnavTopbb,'productnavtopling':isproductnavtopling}" ref="productnavTop">
                <!-- <div class="productnav " > -->
                    <div class="space dajijunav">
                        <div :class="{spaceActiv:isSpaceActiv == index}" v-for="(item, index) in kjdzallData" :key="index" @click="spance(index)">
                            <img class="spaceimg" :src=item.icon alt="">
                            <img class="spaceimgActive" :src=item.icon_active alt="">
                            <p>{{item.name}}</p>
                        </div>
                    </div>
                </div>
        <div class="jianbianq"  :class="{'jianbianh':isbrandConcepttitle}" ref="brandConcepttitle">
        <div class="founder spaceCustomization">
            <div class="yishu marginTX"></div>
            <div class="beijing"><img src="@/common/image/christophlogo.png" alt=""></div>
            <p>CHRISTOPH The lamp is named after its designer, physicist Christoph Kügler.</p>
            <p>In a positive sense, he was fascinated by light.</p>
            <p>These lights combine a love of detail with the quality of materials and new smart technology.</p>
            <p>It is a lamp that meets high professional standards and is suitable for your personal application.</p>
            <p>CHRISTOPH Light system, Germany design, Germany technology.</p>
            <p>Art and luxury can be used in every corner of dining room, living room, kitchen, bedroom.</p>
            <p>With smooth lines, it realizes a harmony between light and shadow effect and space aesthetics, integrates into the functional space naturally, as well as plays the role of decoration to enhance the sense of spatial hierarchy.</p>
            <p>The bright colors of the lights are matched with refreshing and elegant line design, and the simple lines outline the monologue of light and shadow, leaving more space and thinking, and interpreting the texture of high-end life.</p>
            <p>What is created is a kind of exquisite, aesthetic and immersive visual effect.</p>
            <div class="moreBut margintopt" @click="todajiju">Learn More</div>
        </div>
        </div>
        <div class="jianbianq"  :class="{'jianbianh':issjmx}" ref="sjmx">
        <div class="founder spaceCustomization">
            <div class="zhontu"><img src="@/common/image/cbrand1.png" alt=""></div>
            <div class="yishu marginTX"></div>
            <div class="beijing pwjstrone">Different space deduction</div>
            <p>Different Spaces，Same Spectacular</p>
            <div class="moreBut margintopt" @click="todajiju">Learn More</div>
        </div>
        </div>
        <div class="jianbianq positre"  :class="{'jianbianh':iskjmx}" ref="kjmx">

        <div class="enterpriseDynamics_box" :style="{'width':quanpin + 'px'}">
            <div class="enterpriseDynamics_news tran" :style="{marginLeft:newLfet + 'px','width':quanpinl + 'px'}">
                <ul :style="{'width':quanpin + 'px'}" v-for="(item, index) in jiaxiangData" :key="index" style="">
                    <li><img :src=item.img alt=""></li>
                </ul>
            </div>
        </div>
        <!-- <div class="newswi">
            <div class="left" @click="newswiLfet"><p></p></div>
            <div class="center">
                <ul>
                    <li v-for="(item, index) in jiaxiangData" :key="index" :class="{'active':isactive == index}" @click="xuanzhongle(index)"><span class="no">一</span><span class="yes">十</span></li>
                </ul>
            </div>
            <div class="right" @click="newswiRight"><p></p></div>
        </div> -->

                <div class="switeName">
                    <div class="switeNameBox">
                        <div class="left" @click="newswiLfet"><p v-show="isleftno"></p><span></span></div>
                        <!-- <div class="midle" v-for="(item, index) in storeDisplayData" :key="index" v-show="isStoerDisplay == index">{{item.storeName}}</div> -->
                        <div class="right"  @click="newswiRight"><p v-show="isrightno"></p><span></span></div>
                    </div>
                </div>
        </div>

        <!-- <div class="germanBox" ref="germanBox">
            <ul>
                <li>
                    <div>
                        <transition-group name="germanimg">
                            <img :style="{'marginTop':germanimgmta + 'px','opacity':germanimgopa}" src="@/common/image/dengpinpai1.png" alt="">
                            <img :style="{'marginTop':germanimgmtb + 'px','opacity':germanimgopb}" src="@/common/image/dengpinpai2.png" alt="">
                            <img :style="{'marginTop':germanimgmtc + 'px','opacity':germanimgopc}" src="@/common/image/dengpinpai3.png" alt="">
                        </transition-group>
                    </div>
                    <div>
                        <p class="patopo">
                            <span :style="{'marginTop':germanspanmta + 'px','opacity':germanspanopa}">German Brand</span>
                            <span :style="{'marginTop':germanspanmtb + 'px','opacity':germanspanopb}">German Design</span>
                            <span :style="{'marginTop':germanspanmtc + 'px','opacity':germanspanopc}">German Mind</span>
                        </p>
                        <p>
                            <span :style="{'marginTop':germanspanmta + 'px','opacity':germanspanopa}">德国品牌</span>
                            <span :style="{'marginTop':germanspanmtb + 'px','opacity':germanspanopb}">德国设计</span>
                            <span :style="{'marginTop':germanspanmtc + 'px','opacity':germanspanopc}">德国思维</span>
                        </p>
                    </div>
                </li>
            </ul>
        </div> -->

    </div>
</template>

<script>
import sofa from '@/common/image/sofa.png'
import kitchen from '@/common/image/kitchen.png'
import sofa_active from '@/common/image/sofa_active.png'
import kitchen_active from '@/common/image/kitchen_active.png'
import dajiajuhome1 from '@/common/image/dajiajuhome1.png'
import dajiajuhome2 from '@/common/image/dajiajuhome2.png'
import dajiajuhome3 from '@/common/image/dajiajuhome3.png'



export default {
  data(){
      return{
            kjdzData:[
                {
                    name:'空间定制',eName:'Space Customization'
                },
                {
                    name:'大家居',eName:'Lighting & Furniture'
                },
                {
                    name:'门墙系统',eName:'Door & Wall'
                },
            ],
            kjdzallData:[
                {
                    icon:kitchen,
                    icon_active:kitchen_active,
                    name:'Light',
                },
                {
                    icon:sofa,
                    icon_active:sofa_active,
                    name:'Soft furnishings',
                },
            ],
            ishdMask:true,
            midlIndex:1,
            leftIndex:0,
            rightIndex:2,
            isSpaceCustomization:1,
            hdmHeight:920,
            kjdzallIndex:0,
            isSpaceActiv:0,
            isproductnavTop:false,
            productnavTopH:709,
            jiaxiangData:[
                {
                    img:dajiajuhome1,
                },
                {
                    img:dajiajuhome2,
                },
                {
                    img:dajiajuhome3,
                },
            ],
            newLfet:0,
            isactive:0,
            quanpin:document.body.clientWidth,
            quanpinl:99999,
            germanimgmta:0,
            germanimgmtb:0,
            germanimgmtc:0,
            germanimgopa:1,
            germanimgopb:1,
            germanimgopc:1,
            germanspanmta:60,
            germanspanmtb:120,
            germanspanmtc:60,
            germanspanopa:1,
            germanspanopb:1,
            germanspanopc:1,
            offtop:0,
            guoduheight:0,
            iscaseBaner:false,
            isbrandConcepttitle:false,
            issjmx:false,
            iskjmx:false,
            isleftno:false,
            isrightno:true,
            isproductnavTopaa:false,
            isproductnavTopbb:false,
            isproductnavtopling:false,
            dajijuyrl:'http://christoph.cn'
      }
  },
    mounted() {
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mHomeProducts');
        } else {
            this.$router.replace(paths+'/homeProducts');
        }
        setInterval(() => {
            this.maskHeight()
        }, 1000)
        window.addEventListener("scroll", this.productNavScroll);
       window.addEventListener("scroll", this.handleScroll);
        this.iscaseBaner = true

    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        handleScroll(){
            this.$nextTick(()=>{
                this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
            // if (e.deltaY>0) {
                if (this.$refs.brandConcepttitle.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = true
                }
                if (this.$refs.sjmx.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.issjmx = true
                }
                if (this.$refs.kjmx.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.iskjmx = true
                }
                if(this.isproductnavTop == true){
                    this.isproductnavTopaa = false
                    this.isproductnavTopbb = true
                }

            // }else if (e.deltaY < 0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.issjmx = false
                }
                if (this.$refs.kjmx.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.iskjmx = false
                }
                if(this.isproductnavTop == true){
                    this.isproductnavTopaa = true
                    this.isproductnavTopbb = false
                }
            })
            // }

        },
        maskHeight(){
            // this.hdmHeight = this.$refs.homeDecoration.clientHeight
            // this.productnavTopH = this.$refs.productnavTop.offsetTop
            // console.log(this.productnavTopH)
            this.quanpinl = this.quanpin * this.jiaxiangData.length
            this.offtop = this.$refs.germanBox.offsetTop
        },
        defferScroll: function (event) {
            event.preventDefault()
        },
        germanBoxani(e){
            console.log(this.offtop,this.scroll ,e.deltaY ,this.guoduheight,e)
            if (this.scroll > 3414 && this.scroll <3914) {
                if (this.guoduheight > 1040) {
                    document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
                    this.guoduheight = 0
                    this.germanimgmtc = -520
                    this.germanimgopb = 0
                    this.germanimgopa = 0

                }else{
                    this.guoduheight = this.guoduheight + e.deltaY
                    document.body.addEventListener("wheel", this.defferScroll, {passive: false});
                    if (this.guoduheight<=520) {
                        if (this.guoduheight>520) {
                            this.germanimgmtb = -520
                            this.germanimgopa = 0
                        }else{
                            this.germanimgmtb = -this.guoduheight
                            this.germanimgopa =  1 - this.guoduheight/520
                        }
                    }else{
                        if (this.guoduheight>1040) {
                            this.germanimgmtc = -520
                            this.germanimgopb = 0
                        }else{
                            let newheight = this.guoduheight - 520
                            this.germanimgmtc = -newheight
                            this.germanimgopb =  1 - newheight/520
                        }
                    }
                }
            }else{
                document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
            }
        },
        productNavScroll(){
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
            // this.offtop = this.$refs.germanBox.offsetTop
            if (this.scroll > 710) {
                this.isproductnavTop =true
                this.isproductnavtopling = true
            }else{
                this.isproductnavTop =false
                this.isproductnavTopaa = false
                this.isproductnavTopbb = false
            }
        },
        homeDM(){
            this.ishdMask = true
            this.hdcm = 0
        },
        homeDMout(){
            this.ishdMask = false
            // this.hdcm = -300
        },
        kjdz(index){
             if(this.midlIndex != index){
                if (index == 0) {
                    this.$router.push('/productCenter')
                }else if (index == 2) {
                    this.$router.push('/doorwallsystem')
                }
                this.isSpaceCustomization = index
            }
        },
        spance(index){
            this.kjdzallIndex =index
            this.isSpaceActiv = index
            document.documentElement.scrollTop = this.$refs.roomSpace.offsetTop
        },
        newswiLfet(){
            if (this.isactive>0) {
                this.isactive = this.isactive-1
                this.newLfet = -document.body.clientWidth * this.isactive
                if (this.isactive == 0) {
                    this.isleftno = false
                    this.isrightno = true
                }else{
                    this.isactive = true
                    this.isrightno = true
                }
            }
        },
        newswiRight(){
            if (this.isactive < this.jiaxiangData.length - 1) {
                this.isactive = this.isactive+1
                this.newLfet = -document.body.clientWidth * this.isactive
                if (this.isactive == this.jiaxiangData.length - 1) {
                    this.isleftno = true
                    this.isrightno = false
                }else{
                    this.isleftno = true
                    this.isrightno = true
                }
            }
        },
        xuanzhongle(index){
            this.isactive = index
            this.newLfet = -document.body.clientWidth * this.isactive
        },
        todajiju(){
            window.open(this.dajijuyrl,'_blank')
        },
  },
  unmounted(){
    window.removeEventListener("scroll", this.productNavScroll);
       window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>

<style>
@import '../../../common/styles/productCenter.css';
.homeDecorationCenten{
    position: relative;
}
.christophBrand .productnav{
    /* position: absolute;
    left: 0;
    top:600px; */
    width: 100%;
    text-align: center;
    /* padding: 5px 0; */
}
.productnav .space{
    width: 1200px;
    margin: 0 auto;
    padding-top: 0;
}
.productnav .space div p{
    text-align: center;
    padding-bottom: 0;
}
.christophBrand .productnavTop{
    position: fixed;
    bottom: inherit;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 90%);
    z-index: 999;
}
.dajijunav div img{
    width: 35px;
}
.christophBrand .spaceCustomization p{
    text-align: center;
    padding-bottom: 0;
}
.margintopt{
    margin-top: 3%;
}
.christophBrand .zhontu img{
    width: 100%;
    transform: none;
    margin-top: 25px;
}
.christophBrand .enterpriseDynamics_box{
    width: 100%;
    height: 900px;
}
.christophBrand .enterpriseDynamics_news ul{
    width: 100%;
}
.christophBrand .enterpriseDynamics_news ul li{
    width: 100%;
    flex: 1 100%;
    margin: 0;
    padding: 0;
}
.christophBrand .enterpriseDynamics_news ul li img{
    width: 100%;
}
.germanBox{
    width: 100%;
    height: 520px;
    vertical-align: middle;
    text-align: center;
    margin: 0 auto;
    overflow: hidden;
}
.germanBox ul li{
    width: 1200px;
    margin: 0 auto;
    display: flex;
}
.germanBox ul li>div{
    width: 600px;
    height: 520px;
    flex: 1 600px;
}
.germanBox ul li>div img{
    width: 100%;
    height: 520px;
}
.germanBox ul li>div p{
    width: 100%;
    text-align: center;
    font-size: 50px;
    font-weight: 100;
    color: #4c413d;
    line-height: 60px;
    height: 180px;
    overflow: hidden;
    padding-top: 45px;
}
.germanBox ul li>div p.patopo{
    /* height: 210px; */
}
.germanBox ul li>div p span{
    display: block;
}
.germanimg-move{
    transition: all .5s;
}
.product .productkjdz ul li{
    padding-top: 120px;
    cursor: pointer;
}
.product .productkjdz ul li p{
    display: none;
}
.product .productkjdz ul li.midl p{
    display: block;
    margin-top: 10px;
}
.product .productkjdz ul li.midl{
    border-bottom: none;
    flex: 4;
    font-size: 2.5em;
    padding-top: 60px;
    overflow: initial;
}
.product .productkjdz ul li.midl>div{
    letter-spacing: 10px;
}
.product .productkjdz ul li.left{
    text-align: left;
}
.product .productkjdz ul li.right{
    text-align: right;
}
.product .homeDecorationCenten ul{
    padding-top: 150px;
}
.positre{
    position: relative;
}
.positre .switeName{
    position: absolute;
    left: 0;
    top: 375px;
}
.midl .zhoyao{
  opacity: 0;
}
</style>
