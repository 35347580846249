<template>
  <div class="mhome">
    <div class="homelogo">
      <video id="music_mp3_0"  width="1200" :src="'https://www.vifa.cn' + homeBanner.video" controls
        p></video>
    </div>
    <!-- <button @click="aaa">用作测试</button> -->
    <div class="hometopswipt quanpin">
      <div class="xiaotop"></div>
      <div class="pinpailishi">
        <div class="pplstext">
          <div class="title pwjstrone">VIFA Customization Supreme Simplicity</div>
          <p>
            vifa provides complete solutions for customizing your home, from space, door and wall systems to household
            products. VIFA offers a unique style that gives you a new taste with a thoughtful choice of materials and
            detailed design. From the inside to the outside, the harmony between form and color is fascinating. VIFA has
            reshaped home life by rethinking and redefining it. Intelligent life and exquisite detail design complement
            each other, creating a new whole.
          </p>
        </div>
        <div class="pplsimg">
          <transition-group name="pinpailishiani">
            <ul :style="{
              marginLeft: kjmarginLeft + 'px',
              width: kjwidth + 'px',
            }">
              <li v-for="(item, index) in productCenterBanner" :key="index" @touchstart="kjtouchstart"
                @touchmove="kjtouchmove">
                <img :src="'https://www.vifa.cn' + item.image" alt="" @click="tolink(index)" />
              </li>
            </ul>
          </transition-group>
        </div>
        <div class="bottomtitle">
          <div class="showtitle" v-for="(item, index) in productCenterBanner" :key="index" v-show="iskjshow == index">
            <p>{{ item.title }}</p>
            <!-- <p>{{ item.subtitle }}</p> -->
          </div>
          <div class="toleft" @click="hkjfl"><span v-show="hkjfls"></span></div>
          <div class="toright" @click="hkjfr">
            <span v-show="hkjfrs"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="hometopswipt quanpin">
      <div class="xiaotop"></div>
      <div class="pinpailishi">
        <div class="pplstext pplstextx">
          <div class="title pwjstrone">We are where you are</div>
          <div class="input">
            <input type="text" placeholder="Please enter your city" v-model="mycity" @keyup.enter="citychashop" />
            <div class="img" @click="citychashop">
              <img src="@/common/image/searchIco.png" alt="" />
            </div>
          </div>
          <p>
            vifa, with its high-quality store environment, provides every customer with the best service experience, and
            presents a variety of ideal home decoration combination form with real cases.
          </p>
        </div>
        <div class="pplsimg">
          <transition-group name="pinpailishiani">
            <ul :style="{
              marginLeft: mdmarginLeft + 'px',
              width: mdwidth + 'px',
            }">
              <li v-for="(item, index) in homeVifaAround" :key="index" @touchstart="mdtouchstart"
                @touchmove="mdtouchmove">
                <img :src="'https://www.vifa.cn' + item.mobileImage" alt="" />
              </li>
            </ul>
          </transition-group>
        </div>
        <div class="bottomtitle">
          <div class="showtitle" v-for="(item, index) in homeVifaAround" :key="index" v-show="ismdshow == index">
            <p>{{ item.nameEng }}</p>
            <!-- <p>地址：{{item.cityAddress}}</p> -->
          </div>
          <div class="toleft" @click="hmqzsl">
            <span v-show="ishmqzsl"></span>
          </div>
          <div class="toright" @click="hmqzsr">
            <span v-show="ishmqzsr"></span>
          </div>
        </div>
      </div>
      <!-- <div class="mkaiping" v-show="islaipin"> -->
      <!-- <video poster="" width="1200"  :src="'https://www.vifa.cn' + homeBanner.mobileLoad"  preload='preload' autoplay='autoplay' :muted='mutedautop'>
                </video> -->

      <!-- <img :src="'https://www.vifa.cn' + homeBanner.mobileLoad" alt="">
            </div> -->
      <div class="tishi" v-if="istishi">
        <div>{{ tishi }}</div>
      </div>
    </div>
    <Mzixun />
  </div>
</template>

<script>
import Mzixun from "@/components/mzixun";
import { getHomePage } from "@/api/api";
export default {
  props: ["isaaat"],
  data() {
    return {
      homeBrandIntroduce: [],
      ishbishow: 1,
      pplsstartx: 0,
      pplsmovex: 0,
      pplschangex: 0,
      pplsmarginLeft: "",
      pplswidth: 0,
      pplsliwidth: 0,
      pplsuloffsetLeft: 0,
      productCenterBanner: [],
      kjmarginLeft: -document.body.clientWidth,
      kjwidth: 0,
      iskjshow: 1,
      kjstartx: 0,
      kjmovex: 0,
      kjchangex: 0,
      homeVifaAround: [],
      mdmarginLeft: 0,
      mdwidth: 0,
      ismdshow: 0,
      mdstartx: 0,
      mdmovex: 0,
      mdchangex: 0,
      homeBanner: "",
      mutedautop: "false",
      islaipin: true,
      mycity: "",
      tishi: "",
      istishi: false,
      isvideoshow: true,
      hkjfls: true,
      hkjfrs: true,
      ishmqzsl: false,
      ishmqzsr: true,
    };
  },
  components: {
    Mzixun,
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/m_home");
    } else {
      this.$router.replace(paths + "/");
    }
    this.getHomePageData();
    // document.body.addEventListener("touchmove", this.defferScroll, {passive: false});
    // document.body.addEventListener("wheel", this.defferScroll, {passive: false});
    setTimeout(() => {
      this.$nextTick(() => {
        if(this.$refs.pplsli0){
          this.pplsliwidth = this.$refs.pplsli0.clientWidth;
        this.pplswidth =
          (this.$refs.pplsli0.clientWidth + 1) * this.homeBrandIntroduce.length;
        this.pplsuloffsetLeft = this.$refs.pplsul.offsetLeft;
        this.pplsmarginLeft = this.pplsuloffsetLeft;
        this.kjwidth =
          document.body.clientWidth * this.productCenterBanner.length;
        }
      });
    }, 1000);
    // setTimeout(() => {
    //     this.mutedautop = 'autoplay'
    //     document.body.removeEventListener("touchmove", this.defferScroll, {passive: false});
    //     document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
    //     this.islaipin = false
    //     this.isvideoshow = true
    // }, 6000)
    document.addEventListener("DOMContentLoaded", function () {
      function audioAutoPlay() {
        var musicEle0 = document.getElementById("music_mp3_0");
        musicEle0.play();
      }
      audioAutoPlay();
    });
  },
  methods: {
    aaa() {
      console.log(this.isaaat);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getHomePageData() {
      getHomePage()
        .then((res) => {
          this.homeBrandIntroduce = res.homeBrandIntroduce;
          this.productCenterBanner = [
            {
              image: res.productCenterBanner.mobileMqImage,
              title: "Door & Wall",
              subtitle: "Door & Wall",
            },
            {
              image: res.productCenterBanner.mobileKjdzImage,
              title: "Space Customization",
              subtitle: "Space Customization",
            },
            {
              image: res.productCenterBanner.mobileDjjImage,
              title: "Lighting & Furniture",
              subtitle: "Lighting & Furniture",
            },
          ];
          this.homeVifaAround = res.homeVifaAround;
          this.homeBanner = res.homeBanner;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    defferScroll: function (event) {
      event.preventDefault();
    },
    show(index) {
      this.pplschangex = this.pplsstartx;
      this.pplsmarginLeft =
        this.pplsuloffsetLeft - this.pplsliwidth * (index - 1);
    },
    pplstouchstart(e) {
      this.pplsstartx = e.touches[0].clientX;
    },
    pplstouchmove(e) {
      if (this.pplschangex == this.pplsstartx) {
        return;
      }
      this.pplsmovex = e.touches[0].clientX;
      let leftslide = this.pplsstartx - this.pplsmovex;
      console.log(this.ishbishow);
      if (
        leftslide > 30 &&
        this.ishbishow < this.homeBrandIntroduce.length - 1
      ) {
        this.show(++this.ishbishow);
      } else if (leftslide < -30 && this.ishbishow > 0) {
        this.show(--this.ishbishow);
      }
    },
    kjshow(index) {
      this.kjchangex = this.kjstartx;
      this.kjmarginLeft = -document.body.clientWidth * index;
      if (this.iskjshow == 0) {
        this.hkjfls = false;
        this.hkjfrs = true;
      } else if (this.iskjshow == this.productCenterBanner.length - 1) {
        this.hkjfls = true;
        this.hkjfrs = false;
      } else {
        this.hkjfls = true;
        this.hkjfrs = true;
      }
      console.log(this.hkjfls, index, this.iskjshow);
    },
    kjtouchstart(e) {
      this.kjstartx = e.touches[0].clientX;
    },
    kjtouchmove(e) {
      if (this.kjchangex == this.kjstartx) {
        return;
      }
      this.kjmovex = e.touches[0].clientX;
      let kjleftslide = this.kjstartx - this.kjmovex;
      console.log(this.iskjshow);
      if (
        kjleftslide > 30 &&
        this.iskjshow < this.productCenterBanner.length - 1
      ) {
        this.kjshow(++this.iskjshow);
      } else if (kjleftslide < -30 && this.iskjshow > 0) {
        this.kjshow(--this.iskjshow);
      }
    },
    hkjfl() {
      if (this.iskjshow > 0) {
        this.kjshow(--this.iskjshow);
      }
    },
    hkjfr() {
      if (this.iskjshow < this.productCenterBanner.length - 1) {
        this.kjshow(++this.iskjshow);
      }
    },
    mdshow(index) {
      this.mdchangex = this.mdstartx;
      this.mdmarginLeft = -document.body.clientWidth * index;
      if (this.ismdshow == 0) {
        this.ishmqzsl = false;
        this.ishmqzsr = true;
      } else if (this.ismdshow == this.homeVifaAround.length - 1) {
        this.ishmqzsl = true;
        this.ishmqzsr = false;
      } else {
        this.ishmqzsl = true;
        this.ishmqzsr = true;
      }
    },
    mdtouchstart(e) {
      this.mdstartx = e.touches[0].clientX;
    },
    mdtouchmove(e) {
      if (this.mdchangex == this.mdstartx) {
        return;
      }
      this.mdmovex = e.touches[0].clientX;
      let mdleftslide = this.mdstartx - this.mdmovex;
      console.log(this.ismdshow);
      if (mdleftslide > 30 && this.ismdshow < this.homeVifaAround.length - 1) {
        this.mdshow(++this.ismdshow);
      } else if (mdleftslide < -30 && this.ismdshow > 0) {
        this.mdshow(--this.ismdshow);
      }
    },

    hmqzsl() {
      if (this.ismdshow > 0) {
        this.mdshow(--this.ismdshow);
      }
    },
    hmqzsr() {
      if (this.ismdshow < this.homeVifaAround.length - 1) {
        this.mdshow(++this.ismdshow);
      }
    },
    tolink(index) {
      if (index == 0) {
        this.$router.push("/mdoorwallsystem");
      } else if (index == 1) {
        this.$router.push("/mProductCenter");
      } else if (index == 2) {
        this.$router.push("/mHomeProducts");
      }
    },
    citychashop() {
      if (this.citychashop == "") {
        this.istishi = true;
        this.tishi = "请输入您所在的城市";
        setTimeout(() => {
          this.istishi = false;
        }, 3000);
      } else {
        let paths;
        if (sessionStorage.getItem("language") == 1) {
          paths = "";
        } else if (sessionStorage.getItem("language") == 2) {
          paths = "/En";
        } else {
          paths = "/It";
        }
        this.$router.push({
          path: paths + "/mcontact",
          query: { suosuov: this.mycity },
        });
      }
    },
  },
  unmounted(){
           document.removeEventListener("DOMContentLoaded", function () {
      function audioAutoPlay() {
        var musicEle0 = document.getElementById("music_mp3_0");
        musicEle0.play();
      }
      audioAutoPlay();
    });
    }
};
</script>

<style scoped>
.homelogo {
  width: 100%;
  margin-bottom: 100px;
}

.xiaotop {
  width: 1px;
  height: 34px;
  background: #f08518;
  margin: 35px auto 0;
}

.pplstext {
  line-height: 1.8;
  width: 670px;
  margin: 0 auto 100px;
}

.pinpailishi .title {
  font-size: 45px;
  padding: 20px 0;
  padding: 20px 0;
}

.pinpailishi .subtitle {
  font-size: 30px;
}

.pinpailishi p {
  font-size: 26px;
  padding-bottom: 20px;
  text-align: justify;
}

.pplsimg {
  width: 100%;
  height: 492px;
  padding: 20px 0;
  overflow: hidden;
}

.pinpailishi ul {
  /* width: 2490px; */
  display: flex;
  margin-left: -247.5px;
}

.pinpailishi ul li {
  width: 375px;
  height: 492px;
  flex: 1;
  padding: 0 20px;
}

.pinpailishi ul li img {
  width: 375px;
  height: 492px;
}

.bottomtitle {
  width: 670px;
  margin: 0 auto;
  color: #f08518;
  font-size: 30px;
  line-height: 1.5;
  position: relative;
}

.bottomtitle .showtitle {}

.bottomtitle .showtitle p {
  text-align: center;
  padding-bottom: 0;
}

.bottomtitle .toleft {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  left: -85px;
  top: 20px;
}

.bottomtitle .toright {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  right: -85px;
  top: 20px;
}

.bottomtitle .toleft span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  left: 10px;
  top: -9px;
}

.bottomtitle .toright span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  right: 10px;
  top: -9px;
}

.pinpailishiani-move {
  transition: all 0.3s;
}

.quanpin .bottomtitle {
  color: #000;
  width: 500px;
}

.quanpin .pplsimg {
  height: 447px;
}

.quanpin .pinpailishi ul {
  width: 2250px;
  margin: 0;
}

.quanpin .pinpailishi ul li {
  width: 750px;
  height: 447px;
  padding: 0;
}

.quanpin .pinpailishi ul li img {
  width: 750px;
  height: 447px;
}

.input {
  font-size: 26px;
  width: 670px;
  margin: 20px auto;
}

.input input {
  width: 100%;
  line-height: 67px;
  border: 1px solid #4c413d4d;
  text-align: center;
}

.mkaiping {
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999999999;
  text-align: center;
  display: flex;
  align-items: center;
}

.mkaiping img {
  width: 100%;
  height: auto;
}

.tishi {
  width: 100%;
  color: #fff;
  position: fixed;
  top: 50%;
  margin-top: -30px;
  left: 0;
}

.tishi div {
  display: inline-block;
  padding: 20px 50px;
  background: #000;
  border-radius: 10px;
}

.hometopswipt {
  padding-bottom: 100px;
}

.pplstextx {
  margin-bottom: 60px;
}

.input {
  position: relative;
}

.input input {
  line-height: 70px;
  height: 70px;
}

.input .img {
  position: absolute;
  top: 2px;
  right: 0;
  text-align: center;
  padding: 11px;
  padding-right: 20px;
  cursor: pointer;
  background: none;
}

.input .img img {
  width: 40px;
  height: 40px;
}
</style>
