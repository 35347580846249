<template>
  <div
    class="home"
    :class="{ hometopanima: ishometopanima }"
    @contextmenu.prevent="youjian()"
  >
    <!-- <div class="logovideo" :class="{'logovideodan':islogovideodan}"> -->
    <!-- <img class="logoloadbg" :src=item.img alt="" v-for="(item, index) in logoloadbgData" :key="index" v-show="islogoloadbg == index">
                <img class="logoload" src="../common/image/logoload.gif" alt=""> -->

    <!-- <video ref="kaipinvideo" poster="" :src="'https://www.vifa.cn' + homeBanner.pcLoad"  preload='preload' autoplay='autoplay' :muted='mutedautop'></video>
            </div> -->
    <div class="banner" :class="{ bannerMaskani: isbannerani }">
      <div class="bannerMask" >
        <video
          @click="kjdzdwAa"
          poster=""
          width="1200"
          src="https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/video/VifaIndex.m4v"
          preload="preload"
          loop="false"
          autoplay="autoplay"
          :muted="mutedautop"
          controls="controls"

        ></video>
        <div class="bannerMaskLeft"></div>
        <div class="bannerMaskRieght"></div>
      </div>
    </div>
    <!-- <div class="founder homefounder" ref="foundermt">
            <div class="yishu"></div>
            <div class="brandBox">
            <transition-group name="brandtransitionani">
                <div class="brand" v-for="(item, index) in founderUl" :key="index" :class="{'brandani':hover == index}">
                    <div class="beijing brandtext">{{item.title}}</div>
                    <div class="time brandtext">{{item.subtitle}}</div>
                    <p v-if="item.oneText" class="brandtext">{{item.oneText}}</p>
                    <p v-if="item.twoText" class="brandtext">{{item.twoText}}</p>
                    <p v-if="item.threeText" class="brandtext">{{item.threeText}}</p>
                    <p v-if="item.fourText" class="brandtext">{{item.fourText}}</p>
                    <img :src="'https://www.vifa.cn' + item.image" alt="" class="brandtransition">
                </div>
            </transition-group>
            </div>
            <ul>
                <li v-for="(item, index) in founderUl" :key="index" :class="{'active':hover==index}" @mouseover="founderUlMouseover(index)">
                    <div class="cursor">
                        <p class="cursorNum">{{item.quantity}}</p>
                        <p>{{item.quantityUnit}}</p>
                    </div>
                </li>
            </ul>
            <transition-group name="yishuani">
                <div class="yishu yishuani" :style="{marginLeft:yishuani + 'px'}"></div>
            </transition-group>
            <div class="jiaolian" ref="jiaolianref"></div>
        </div> -->
    <div class="jianbianq" :class="{ jianbianh: iskjdztitle }" ref="kjdztitle">
      <div class="founder spaceCustomization">
        <div class="yishu"></div>
        <div class="beijing">威法定制 大宅至简</div>
        <p>
          vifa提供完整的家居定制解决方案。从空间定制、门墙系统到大家居产品。周致的材料选择和细节设计，不同于你所见过或感觉到的任何产品。<br />从内到外，形式与色彩的和谐令人着迷。<br />vifa彻底重新思考和定义了家居生活。智能生活与精确的细节设计相结合，形成一个全新的整体。
        </p>
      </div>
      <div
        class="spaceCustomization_img homeDecoration kjdz homespanceheight"
        ref="homeDecoration"
        @mouseenter="homeDM"
        @mouseleave="homeDMout"
      >
        <!-- <transition-group name="spaceCustomizationIngT"> -->
        <!-- <div class="kjpiyi"> -->
        <div
          class="spaceCustomization"
          v-for="(item, index) in kjdzData"
          :key="index"
          :class="{ spaceCustomizationani: isSpaceCustomization == index }"
        >
          <img :src="item.img" alt="" />
        </div>
        <!-- </div> -->
        <!-- </transition-group> -->
        <div
          class="homeDecorationMask"
          :style="{ height: hdmHeight + 'px' }"
          v-show="ishdMask"
          :class="{ backgroundani: backgroundani }"
        >
          <div class="homeDecorationCenten">
            <!-- <transition-group name="hdcm"> -->
            <ul
              :class="{
                kjdzanimaleft: kjdzanimaleft,
                kjdzanimaright: kjdzanimaright,
              }"
            >
              <li @click="kjdzdw" class="left">
                <div>
                  <div>{{ dwname }}</div>
                  <p>{{ dwename }}</p>
                </div>
              </li>
              <li @click="kjdzsc" class="midl">
                <div>
                  <div>{{ scname }}</div>
                  <p>{{ scename }}</p>
                </div>
              </li>
              <li @click="kjdzld" class="right">
                <div>
                  <div>{{ ldname }}</div>
                  <p>{{ ldename }}</p>
                </div>
              </li>
            </ul>
            <!-- </transition-group> -->
            <div
              class="more"
              @mouseleave="moremouseleave"
              :class="{ moreleav: ismoreleav }"
              @click="tocpzx"
            >
              了解更多
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jianbianq" :class="{ jianbianh: issearchani }" ref="searchani">
      <div class="search">
        <div class="searchWap">
          <div class="searchCenten">
            <div class="founder">
              <div class="yishu"></div>
              <div class="beijing">vifa在您身边</div>
            </div>
            <div class="search_input">
              <input
                type="text"
                placeholder="请输入您所在的城市"
                v-model="suosuov"
              />
              <div class="img" @click="suosuo">
                <img src="../common/image/searchIco.png" alt="" />
              </div>
            </div>
            <p>vifa以优质的门店环境，为每一位到店顾客提供周致的服务体验，</p>
            <p>以真实的装修实例，为您呈现多种理想家装组合形式。</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="jianbianq"
      :class="{ jianbianh: isdianmianani }"
      ref="dianmianani"
    >
      <div
        class="spaceCustomization_img homeDecoration storeDisplay ditumendian"
        ref="homeDecoration"
        @mouseenter="sdover"
        @mouseleave="sdout"
      >
        <div class="storeShowImg tran" :style="{ width: storeWidth + 'px' }">
          <div
            class="ssiamge"
            :style="{ width: storeWidth + 'px', left: ssiamgeleft + 'px' }"
            :class="{ tran: noLast }"
          >
            <img
              :src="'https://www.vifa.cn' + item.image"
              alt=""
              v-for="(item, index) in storeDisplayData"
              :key="index"
              :style="{ width: storeImgWidth + 'px' }"
              :ref="'storeShowImg' + index"
              :class="{ ssanin: ssanin == index }"
            />
          </div>
        </div>
        <div
          class="homeDecorationMask"
          :style="{ height: hdmHeight + 'px' }"
          :class="{ backgroundani: issdMask }"
        >
          <!-- <div v-show="issdMask"> -->
          <div
            class="homeDecorationCenten"
            :style="{ height: hdmHeight + 'px' }"
          >
            <ul>
              <li
                v-for="(item, index) in storeDisplayData"
                :key="index"
                :class="{ active: isStoerDisplay == index }"
                @click="storeCity(index)"
              >
                {{ item.city }}
              </li>
            </ul>

            <!-- <div class="more" @mouseleave="moremouseleave" :class="{moreleav:ismoreleav}">了解更多</div> -->
            <div
              class="diannamezi "
              v-for="(item, index) in storeDisplayData"
              :key="index"
              v-show="isStoerDisplay == index"
            >
              <p >{{ item.name }}</p>
              <span>{{ item.cityEname }}</span>
            </div>
            <div
              class="xiangqingcase"
              v-for="(item, index) in storeDisplayData"
              :key="index"
              v-show="isStoerDisplay == index"
            >
              地址：{{ item.cityAddress }}
              <!-- <span></span>电话：{{item.cityPhone}} -->
            </div>
          </div>
          <!-- </div> -->
        </div>
        <div class="switeName">
          <div class="switeNameBox">
            <div class="left" @click="swiperLeft">
              <p v-show="isleftno"></p>
              <span></span>
            </div>
            <div class="right" @click="swiperRight">
              <p v-show="isrightno"></p>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="jianbianq" :class="{ jianbianh: iszixunani }" ref="zixunani">
      <Zixun />
    </div>
  </div>
</template>

<script>
import spaceCustomization from "../common/image/spaceCustomization.jpg";
import doorWall from "../common/image/doorWall.jpg";
import lightDecoration from "../common/image/lightDecoration.jpg";
import logoloadbg1 from "../common/image/logoloadbg1.jpg";
import Zixun from "./zixun";
import { getHomePage } from "../api/api";

export default {
  name: "home",
  data() {
    return {
      logoloadbgData: [{ img: logoloadbg1 }],
      isbannerani: false,
      hover: 0,
      founderUl: [],
      storeDisplayData: [],
      kjdzData: [
        {
          img: doorWall,
        },
        {
          img: spaceCustomization,
        },
        {
          img: lightDecoration,
        },
      ],
      dwname: "门墙系统",
      dwename: "Door & Wall",
      scname: "空间定制",
      scename: "Space Customization",
      ldname: "大家居",
      ldename: "Lighting & Furniture",
      itemP: "",
      yishuani: 147,
      hdmHeight: 710,
      ishdMask: true,
      issdMask: false,
      hdcm: -300,
      isStoerDisplay: 0,
      midlIndex: 1,
      leftIndex: 0,
      rightIndex: 2,
      isSpaceCustomization: 1,
      storeImgWidth: document.body.clientWidth,
      storeWidth: 0,
      storeHeight: 0,
      ssiamgeleft: 0,
      noLast: true,
      sdimgshow: 0,
      kjdzanimaleft: false,
      kjdzanimaright: false,
      ismoreleav: false,
      noposition: true,
      ssanin: 9999,
      isleftno: false,
      isrightno: true,
      islogovideodan: false,
      mutedautop: "false",
      islogoloadbg: 0,
      ishometopanima: false,
      iskjdztitle: false,
      issearchani: false,
      isdianmianani: false,
      iszixunani: false,
      backgroundani: false,
      jiaolianrefwidth: 1200,
      yishuanicl: 100,
      HomePageData: {},
      isvideoshow: true,
      suosuov: "",
      homeBanner: {
        video:''
      },
    };
  },
  components: {
    Zixun,
    // Header,
    // Footer,
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/m_home");
    } else {
      this.$router.replace(paths + "/");
    }
    this.getHomePageData();
    let homeani = 1;
    localStorage.setItem("homeani", homeani);
    // document.body.addEventListener("touchmove", this.defferScroll, {passive: false});
    // document.body.addEventListener("wheel", this.defferScroll, {passive: false});
    window.addEventListener("scroll", this.handleScroll);
    setInterval(() => {
      this.maskHeight();
    }, 1000);
    this.storeSwiper();
    // setTimeout(() => {
    //     homeani = 1
    //     localStorage.setItem('homeani', homeani);
    //     this.islogovideodan = true
    //     this.mutedautop = 'autoplay'
    //     document.body.removeEventListener("touchmove", this.defferScroll, {passive: false});
    //     document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
    //     this.isvideoshow = true
    // }, 5500)
    // this.jiaolianrefwidth = this.$refs.jiaolianref.clientWidth
    this.yishuanicl = this.jiaolianrefwidth / 10;
    this.yishuani = this.jiaolianrefwidth / 10;
    window.addEventListener("mousewheel", this.kaiping);
    document.onkeydown = function () {
      if (window.event && window.event.keyCode == 123) {
        event.keyCode = 0;
        event.returnValue = false;
      }
    };
  },
  created() {},
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getHomePageData() {
      getHomePage()
        .then((res) => {
          this.HomePageData = res;
          this.founderUl = res.homeBrandIntroduce;
          this.storeDisplayData = res.homeVifaAround;
          // console.log(res)
          this.homeBanner = res.homeBanner;
        })
        .catch((res) => {
          console.log(res, "catch");
        });
    },
    kaiping(e) {
      if (e.deltaY > 0 && localStorage.getItem("homeani") == 1) {
        this.isbannerani = true;
      }
    },
    youjian() {
      console.log("点击了右键");
    },

    handleScroll() {
      this.$nextTick(() => {
        this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        // if (e.deltaY>0) {
        // if (this.$refs.foundermt.offsetTop < this.scroll +document.documentElement.clientHeight) {
        //     this.ishometopanima = true
        // }
        if (
          this.$refs.kjdztitle.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.iskjdztitle = true;
        }
        if (
          this.$refs.searchani.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.issearchani = true;
        }
        if (
          this.$refs.dianmianani.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isdianmianani = true;
        }
        if (
          this.$refs.zixunani.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.iszixunani = true;
        }

        // }else if (e.deltaY < 0) {
        // if (this.$refs.foundermt.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
        //     this.ishometopanima = false
        // }
        if (
          this.$refs.kjdztitle.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.iskjdztitle = false;
        }
        if (
          this.$refs.searchani.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.issearchani = false;
        }
        if (
          this.$refs.dianmianani.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.isdianmianani = false;
        }
        if (
          this.$refs.zixunani.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.iszixunani = false;
        }
        // }

        // else{
        //     if (e.deltaY > 0 && localStorage.getItem('homeani') == 1) {
        //         this.isbannerani = true
        //     }
        // }
      });
    },
    defferScroll: function (event) {
      event.preventDefault();
    },
    founderUlMouseover(index) {
      this.hover = index;
      this.yishuani =
        this.yishuanicl +
        (this.jiaolianrefwidth / this.founderUl.length) * index;
      this.itemP = this.founderUl[index].p;
    },
    maskHeight() {
      if (this.hdmHeight == 710) {
        this.hdmHeight = this.$refs.homeDecoration.clientHeight;
      }
      this.storeWidth = this.storeImgWidth * this.storeDisplayData.length;
      if (this.storeHeight == 0) {
        this.storeHeight = this.$refs.storeShowImg0.clientHeight;
      }
    },
    homeDM() {
      this.ishdMask = true;
      (this.hdcm = 0), (this.kjdzanimaleft = false);
      this.kjdzanimaright = false;
      this.ismoreleav = false;
      this.backgroundani = true;
    },
    homeDMout() {
      this.ishdMask = true;
      // this.hdcm = -300
      this.backgroundani = false;
    },
    sdover() {
      this.issdMask = true;
      this.hdmHeight = this.$refs.homeDecoration.offsetHeight;
      this.ismoreleav = false;
      this.noposition = false;
    },
    sdout() {
      this.issdMask = false;
    },
    storeSwiper() {
      setInterval(() => {
        if (this.noposition) {
          // console.log(this.noposition)
          if (this.isStoerDisplay < this.storeDisplayData.length - 1) {
            this.isStoerDisplay++;
            this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay;
            this.noLast = true;
            this.isleftno = true;
            if (this.isStoerDisplay == this.storeDisplayData.length - 1) {
              this.isleftno = true;
              this.isrightno = false;
            } else {
              this.isleftno = true;
              this.isrightno = true;
            }
          } else {
            this.isStoerDisplay = 0;
          }
        }
      }, 5000);
      console.log(this.storeDisplayData.length, this.isStoerDisplay);
    },
    storeCity(index) {
      this.ssanin = index;
      this.noposition = false;
      this.isStoerDisplay = index;
      this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay;
      this.noLast = false;
      this.issdMask = false;
    },
    kjdzdwAa(){
      this.$router.push({path:'/homeProducts',query:{isindex:0}})
    },
    kjdzdw() {
      console.log(this.ldname);
      if (this.dwname == "门墙系统") {
        this.isSpaceCustomization = 0;
      } else if (this.dwname == "空间定制") {
        this.isSpaceCustomization = 1;
      } else if (this.dwname == "大家居") {
        this.isSpaceCustomization = 2;
      }
      if (this.kjdzanimaleft) {
        this.kjdzanimaleft = false;
        this.kjdzanimaright = true;
      } else {
        this.kjdzanimaleft = true;
        this.kjdzanimaright = false;
      }
      let odwname = this.dwname;
      let odwename = this.dwename;
      let oscname = this.scname;
      let oscename = this.scename;
      let odldname = this.ldname;
      let odldename = this.ldename;
      setTimeout(() => {
        this.dwname = odldname;
        this.dwename = odldename;

        this.scname = odwname;
        this.scename = odwename;

        this.ldname = oscname;
        this.ldename = oscename;
      }, 1000);
    },
    kjdzld() {
      console.log(this.ldname);
      if (this.ldname == "门墙系统") {
        this.isSpaceCustomization = 0;
      } else if (this.ldname == "空间定制") {
        this.isSpaceCustomization = 1;
      } else if (this.ldname == "大家居") {
        this.isSpaceCustomization = 2;
      }
      if (this.kjdzanimaleft) {
        this.kjdzanimaleft = false;
        this.kjdzanimaright = true;
      } else {
        this.kjdzanimaleft = true;
        this.kjdzanimaright = false;
      }
      let odwname = this.dwname;
      let odwename = this.dwename;
      let oscname = this.scname;
      let oscename = this.scename;
      let odldname = this.ldname;
      let odldename = this.ldename;
      setTimeout(() => {
        this.dwname = oscname;
        this.dwename = oscename;

        this.scname = odldname;
        this.scename = odldename;

        this.ldname = odwname;
        this.ldename = odwename;
      }, 1000);
    },
    tocpzx() {
      if (this.scname == "空间定制") {
        this.$router.push("/productCenter");
      } else if (this.scname == "门墙系统") {
        this.$router.push("/doorwallsystem");
      } else if (this.scname == "大家居") {
        this.$router.push("/christophBrand");
      }
    },
    swiperLeft() {
      this.noLast = true;
      this.noposition = false;
      this.ssanin = 999;
      if (this.isStoerDisplay > 0) {
        this.isStoerDisplay = this.isStoerDisplay - 1;
        this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay;
        if (this.isStoerDisplay == 0) {
          this.isleftno = false;
          this.isrightno = true;
        } else {
          this.isleftno = true;
          this.isrightno = true;
        }
      } else if (this.isStoerDisplay == 0) {
        // this.isStoerDisplay = this.storeDisplayData.length - 1
        // this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay
        // this.isleftno = false
        // this.isrightno = true
      }
    },
    swiperRight() {
      this.noposition = false;
      this.noLast = true;
      this.ssanin = 999;
      if (this.isStoerDisplay < this.storeDisplayData.length - 1) {
        this.isStoerDisplay = this.isStoerDisplay + 1;
        this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay;
        if (this.isStoerDisplay == this.storeDisplayData.length - 1) {
          this.isleftno = true;
          this.isrightno = false;
        } else {
          this.isleftno = true;
          this.isrightno = true;
        }
      } else {
        // this.isStoerDisplay = 0
        // this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay
        // this.noLast = false
        // this.isleftno = true
        // this.isrightno = false
      }
    },
    moremouseleave() {
      this.ismoreleav = true;
    },
    suosuo() {
      let paths;
      if (sessionStorage.getItem("language") == 1) {
        paths = "";
      } else if (sessionStorage.getItem("language") == 2) {
        paths = "/En";
      } else {
        paths = "/It";
      }
      this.$router.push({
        path: paths + "/contact",
        query: { suosuov: this.suosuov },
      });
    },
  },
  unmounted() {

    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("mousewheel",this.kaiping);
  },
};
</script>

<style>
@keyframes kjdzleftani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  50.1% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes kjdzleftani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  50.1% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-moz-keyframes kjdzleftani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  50.1% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-o-keyframes kjdzleftani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  50.1% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes kjdzrightani {
  0% {
    right: 0;
    opacity: 1;
  }
  50% {
    right: 200px;
    opacity: 0;
  }
  50.1% {
    right: -200px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes kjdzrightani {
  0% {
    right: 0;
    opacity: 1;
  }
  50% {
    right: 200px;
    opacity: 0;
  }
  50.1% {
    right: -200px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-moz-keyframes kjdzrightani {
  0% {
    right: 0;
    opacity: 1;
  }
  50% {
    right: 200px;
    opacity: 0;
  }
  50.1% {
    right: -200px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-o-keyframes kjdzrightani {
  0% {
    right: 0;
    opacity: 1;
  }
  50% {
    right: 200px;
    opacity: 0;
  }
  50.1% {
    right: -200px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes kjdzmidleani {
  0% {
    left: 125px;
    opacity: 1;
  }
  50% {
    left: 0;
    opacity: 0;
  }
  50.1% {
    left: 250px;
    opacity: 0;
  }
  100% {
    left: 125px;
    opacity: 1;
  }
}
@-webkit-keyframes kjdzmidleani {
  0% {
    left: 125px;
    opacity: 1;
  }
  50% {
    left: 0;
    opacity: 0;
  }
  50.1% {
    left: 250px;
    opacity: 0;
  }
  100% {
    left: 125px;
    opacity: 1;
  }
}
@-moz-keyframes kjdzmidleani {
  0% {
    left: 125px;
    opacity: 1;
  }
  50% {
    left: 0;
    opacity: 0;
  }
  50.1% {
    left: 250px;
    opacity: 0;
  }
  100% {
    left: 125px;
    opacity: 1;
  }
}
@-o-keyframes kjdzmidleani {
  0% {
    left: 125px;
    opacity: 1;
  }
  50% {
    left: 0;
    opacity: 0;
  }
  50.1% {
    left: 250px;
    opacity: 0;
  }
  100% {
    left: 125px;
    opacity: 1;
  }
}
@keyframes kjdzmidleanir {
  0% {
    left: 125px;
    opacity: 1;
  }
  50% {
    left: 0;
    opacity: 0;
  }
  50.1% {
    left: 250px;
    opacity: 0;
  }
  100% {
    left: 125px;
    opacity: 1;
  }
}
@-webkit-keyframes kjdzmidleanir {
  0% {
    left: 125px;
    opacity: 1;
  }
  50% {
    left: 0;
    opacity: 0;
  }
  50.1% {
    left: 250px;
    opacity: 0;
  }
  100% {
    left: 125px;
    opacity: 1;
  }
}
@-moz-keyframes kjdzmidleanir {
  0% {
    left: 125px;
    opacity: 1;
  }
  50% {
    left: 0;
    opacity: 0;
  }
  50.1% {
    left: 250px;
    opacity: 0;
  }
  100% {
    left: 125px;
    opacity: 1;
  }
}
@-o-keyframes kjdzmidleanir {
  0% {
    left: 125px;
    opacity: 1;
  }
  50% {
    left: 0;
    opacity: 0;
  }
  50.1% {
    left: 250px;
    opacity: 0;
  }
  100% {
    left: 125px;
    opacity: 1;
  }
}
@keyframes pkjdzmidleani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -300px;
    opacity: 0;
  }
  50.1% {
    left: 300px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes pkjdzmidleani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -300px;
    opacity: 0;
  }
  50.1% {
    left: 300px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-moz-keyframes pkjdzmidleani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -300px;
    opacity: 0;
  }
  50.1% {
    left: 300px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-o-keyframes pkjdzmidleani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -300px;
    opacity: 0;
  }
  50.1% {
    left: 300px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes pkjdzmidleanir {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -300px;
    opacity: 0;
  }
  50.1% {
    left: 300px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes pkjdzmidleanir {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -300px;
    opacity: 0;
  }
  50.1% {
    left: 300px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-moz-keyframes pkjdzmidleanir {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -300px;
    opacity: 0;
  }
  50.1% {
    left: 300px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-o-keyframes pkjdzmidleanir {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -300px;
    opacity: 0;
  }
  50.1% {
    left: 300px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes kjdzmrtyani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  50.1% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes kjdzmrtyani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  50.1% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-moz-keyframes kjdzmrtyani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -200px;
  }
  50.1% {
    left: 200px;
    opacity: 1;
  }
  100% {
    left: 0;
  }
}
@-o-keyframes kjdzmrtyani {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  50.1% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes kjdzmltyani {
  0% {
    right: 0;
    opacity: 1;
  }
  50% {
    right: 200px;
    opacity: 0;
  }
  50.1% {
    right: -200px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes kjdzmltyani {
  0% {
    right: 0;
    opacity: 1;
  }
  50% {
    right: 200px;
    opacity: 0;
  }
  50.1% {
    right: -200px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-moz-keyframes kjdzmltyani {
  0% {
    right: 0;
    opacity: 1;
  }
  50% {
    right: 200px;
    opacity: 0;
  }
  50.1% {
    right: -200px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-o-keyframes kjdzmltyani {
  0% {
    right: 0;
    opacity: 1;
  }
  50% {
    right: 200px;
    opacity: 0;
  }
  50.1% {
    right: -200px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
.kjdz ul.kjdzanimaleft li.left div div {
  animation: kjdzleftani 2s;
  -moz-animation: kjdzleftani 2s;
  -webkit-animation: kjdzleftani 2s;
  -o-animation: kjdzleftani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}
.kjdz ul.kjdzanimaright li.left div div {
  animation: kjdzmrtyani 2s;
  -moz-animation: kjdzmrtyani 2s;
  -webkit-animation: kjdzmrtyani 2s;
  -o-animation: kjdzmrtyani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}
.kjdz ul.kjdzanimaleft li.right div div {
  animation: kjdzmltyani 2s;
  -moz-animation: kjdzmltyani 2s;
  -webkit-animation: kjdzmltyani 2s;
  -o-animation: kjdzmltyani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}
.kjdz ul.kjdzanimaright li.right div div {
  animation: kjdzrightani 2s;
  -moz-animation: kjdzrightani 2s;
  -webkit-animation: kjdzrightani 2s;
  -o-animation: kjdzrightani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}
.kjdz ul.kjdzanimaright li.midl > div div {
  animation: kjdzmidleanir 2s;
  -moz-animation: kjdzmidleanir 2s;
  -webkit-animation: kjdzmidleanir 2s;
  -o-animation: kjdzmidleanir 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}
.kjdz ul.kjdzanimaleft li.midl > div div {
  animation: kjdzmidleani 2s;
  -moz-animation: kjdzmidleani 2s;
  -webkit-animation: kjdzmidleani 2s;
  -o-animation: kjdzmidleani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}
.kjdz ul.kjdzanimaright li.midl > div p {
  animation: pkjdzmidleanir 2s;
  -moz-animation: pkjdzmidleanir 2s;
  -webkit-animation: pkjdzmidleanir 2s;
  -o-animation: pkjdzmidleanir 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}
.kjdz ul.kjdzanimaleft li.midl > div p {
  animation: pkjdzmidleani 2s;
  -moz-animation: pkjdzmidleani 2s;
  -webkit-animation: pkjdzmidleani 2s;
  -o-animation: pkjdzmidleani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}
.logovideo {
  width: 100%;
  height: 100%;
  /* background:url('../common/image/logoloadbg.jpg') no-repeat 0 0;
    background-size: 100% 100%; */
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999999;
  display: flex;
  align-items: center;
  background: #000;
}
.logovideo video {
  margin: 0 auto;
  height: auto;
}
.logovideo .logoload {
  /* margin: 0 auto;
    vertical-align: middle; */

  width: 173px;
  height: 130px;
  /* margin-top: 16%;
    width: 10%; */
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -65px;
  margin-left: -86.5px;
}
.logovideo .logoloadbg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 0;
  animation: logovideoimg 4s;
  -moz-animation: logovideoimg 4s;
  -webkit-animation: logovideoimg 4s;
  -o-animation: logovideoimg 4s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
@keyframes logovideoimg {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@-webkit-keyframes logovideoimg {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@-moz-keyframes logovideoimg {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@-o-keyframes logovideoimg {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.logovideodan {
  animation: logovideodani 1s;
  -moz-animation: logovideodani 1s;
  -webkit-animation: logovideodani 1s;
  -o-animation: logovideodani 1s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  /* z-index: -1; */
}
@keyframes logovideodani {
  0% {
    opacity: 1;
    z-index: 99999;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
@-webkit-keyframes logovideodani {
  0% {
    opacity: 1;
    z-index: 99999;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
@-moz-keyframes logovideodani {
  0% {
    opacity: 1;
    z-index: 99999;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
@-o-keyframes logovideodani {
  0% {
    opacity: 1;
    z-index: 99999;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
.home .homefounder {
  margin-top: 125px;
  opacity: 0;
  animation: homehomefounder 2s;
  -moz-animation: homehomefounder 2s;
  -webkit-animation: homehomefounder 2s;
  -o-animation: homehomefounder 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.hometopanima .homefounder {
  animation: hometopanimafounder 2s;
  -moz-animation: hometopanimafounder 2s;
  -webkit-animation: hometopanimafounder 2s;
  -o-animation: hometopanimafounder 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
@keyframes hometopanimafounder {
  0% {
    opacity: 0;
    margin-top: 125px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-webkit-keyframes hometopanimafounder {
  0% {
    opacity: 0;
    margin-top: 125px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-moz-keyframes hometopanimafounder {
  0% {
    opacity: 0;
    margin-top: 125px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-o-keyframes hometopanimafounder {
  0% {
    opacity: 0;
    margin-top: 125px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes homehomefounder {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: 125px;
  }
}
@-webkit-keyframes homehomefounder {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: 125px;
  }
}
@-moz-keyframes homehomefounder {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: 125px;
  }
}
@-o-keyframes homehomefounder {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: 125px;
  }
}
.home .storeDisplay ul {
  position: absolute;
  left: 50%;
  margin-left: -430px;
  bottom: 91%;
  padding-top: 0;
  margin-top: 0;
  line-height: 32px;
  margin-bottom: -16px;
  padding-bottom: 0;
}
.switeName {
  padding: 0;
}
.storeDisplay .more {
  position: absolute;
  left: 50%;
  bottom: 44%;
  margin-bottom: -31px;
  margin-left: -163px;
}
.home .kjdz ul {
  padding-top: 165px;
}
.jianbianq {
  opacity: 0;
  margin-top: 125px;
  animation: homehomefounder 2s;
  -moz-animation: homehomefounder 2s;
  -webkit-animation: homehomefounder 2s;
  -o-animation: homehomefounder 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.home .jianbianh {
  animation: hometopanimafounder 2s;
  -moz-animation: hometopanimafounder 2s;
  -webkit-animation: hometopanimafounder 2s;
  -o-animation: hometopanimafounder 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.home .kjdz ul li.midl > div {
  height: 90px;
  position: relative;
}
.home .kjdz ul li.midl div div {
  position: absolute;
  left: 125px;
  top: 0;
  width: 200px;
  text-align: center;
}
.home .kjdz ul li.midl p {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.kjdz .homeDecorationMask {
  animation: kjdzhomeDecorationMaska 2s;
  -moz-animation: kjdzhomeDecorationMaska 2s;
  -webkit-animation: kjdzhomeDecorationMaska 2s;
  -o-animation: kjdzhomeDecorationMaska 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.kjdz .spaceCustomization {
  position: absolute;
  left: 0;
  right: 0;
}
.kjdz .backgroundani {
  animation: kjdzbackgroundania 2s;
  -moz-animation: kjdzbackgroundania 2s;
  -webkit-animation: kjdzbackgroundania 2s;
  -o-animation: kjdzbackgroundania 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
@keyframes kjdzbackgroundani {
  0% {
    background: none;
    opacity: 0;
  }
  100% {
    background: #00000066;
    opacity: 1;
  }
}
@-webkit-keyframes kjdzbackgroundani {
  0% {
    background: none;
    opacity: 0;
  }
  100% {
    background: #00000066;
    opacity: 1;
  }
}
@-moz-keyframes kjdzbackgroundani {
  0% {
    background: none;
    opacity: 0;
  }
  100% {
    background: #00000066;
    opacity: 1;
  }
}
@-o-keyframes kjdzbackgroundani {
  0% {
    background: none;
    opacity: 0;
  }
  100% {
    background: #00000066;
    opacity: 1;
  }
}
@keyframes kjdzhomeDecorationMask {
  0% {
    background: #00000066;
    opacity: 1;
  }
  100% {
    background: none;
    opacity: 0;
  }
}
@-webkit-keyframes kjdzhomeDecorationMask {
  0% {
    background: #00000066;
    opacity: 1;
  }
  100% {
    background: none;
    opacity: 0;
  }
}
@-moz-keyframes kjdzhomeDecorationMask {
  0% {
    background: #00000066;
    opacity: 1;
  }
  100% {
    background: none;
    opacity: 0;
  }
}
@-o-keyframes kjdzhomeDecorationMask {
  0% {
    background: #00000066;
    opacity: 1;
  }
  100% {
    background: none;
    opacity: 0;
  }
}
@keyframes kjdzbackgroundania {
  0% {
    background: none;
  }
  100% {
    background: #00000066;
  }
}
@-webkit-keyframes kjdzbackgroundania {
  0% {
    background: none;
  }
  100% {
    background: #00000066;
  }
}
@-moz-keyframes kjdzbackgroundania {
  0% {
    background: none;
  }
  100% {
    background: #00000066;
  }
}
@-o-keyframes kjdzbackgroundania {
  0% {
    background: none;
  }
  100% {
    background: #00000066;
  }
}
@keyframes kjdzhomeDecorationMaska {
  0% {
    background: #00000066;
  }
  100% {
    background: none;
  }
}
@-webkit-keyframes kjdzhomeDecorationMaska {
  0% {
    background: #00000066;
  }
  100% {
    background: none;
  }
}
@-moz-keyframes kjdzhomeDecorationMaska {
  0% {
    background: #00000066;
  }
  100% {
    background: none;
  }
}
@-o-keyframes kjdzhomeDecorationMaska {
  0% {
    background: #00000066;
  }
  100% {
    background: none;
  }
}
.home .switeName {
  padding: 0;
}
.ditumendian .homeDecorationMask {
  animation: kjdzhomeDecorationMask 2s;
  -moz-animation: kjdzhomeDecorationMask 2s;
  -webkit-animation: kjdzhomeDecorationMask 2s;
  -o-animation: kjdzhomeDecorationMask 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.ditumendian .backgroundani {
  animation: kjdzbackgroundani 2s;
  -moz-animation: kjdzbackgroundani 2s;
  -webkit-animation: kjdzbackgroundani 2s;
  -o-animation: kjdzbackgroundani 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.home .brandBox {
  width: 1200px;
  position: relative;
  height: 720px;
}
.home .brand {
  position: absolute;
  left: 0;
  top: 0;
}
.home .brand .brandtext {
  animation: homebrand 1s;
  -moz-animation: homebrand 1s;
  -webkit-animation: homebrand 1s;
  -o-animation: homebrand 1s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.home .brand img {
  width: 350px;
  height: 455px;
  animation: homebrand 2s;
  -moz-animation: homebrand 2s;
  -webkit-animation: homebrand 2s;
  -o-animation: homebrand 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.home .brandani img {
  animation: homebrandani 2s;
  -moz-animation: homebrandani 2s;
  -webkit-animation: homebrandani 2s;
  -o-animation: homebrandani 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.home .brandani .brandtext {
  animation: homebrandani 2s;
  -moz-animation: homebrandani 2s;
  -webkit-animation: homebrandani 2s;
  -o-animation: homebrandani 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.kjdz .spaceCustomization {
  animation: homebrand 2s;
  -moz-animation: homebrand 2s;
  -webkit-animation: homebrand 2s;
  -o-animation: homebrand 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.kjdz .spaceCustomizationani {
  animation: homebrandani 2s;
  -moz-animation: homebrandani 2s;
  -webkit-animation: homebrandani 2s;
  -o-animation: homebrandani 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
@keyframes homebrand {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes homebrand {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes homebrand {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes homebrand {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes homebrandani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes homebrandani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes homebrandani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes homebrandani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.homespanceheight {
  width: 1920px;
  height: 720px;
}
.home .homespanceheight img {
  width: 1920px;
  height: 720px;
}
.homespanceheight .homeDecorationMask {
  width: 1920px;
  height: 720px;
}
.cases .ditumendian .switeName {
  background: none;
  bottom: 50%;
}
.diannamezi {
  font-size: 30px;
  position: absolute;
  width: 1200px;
  top: 50%;
  left: 0;
  line-height: 60px;
  margin-top: -60px;
}
.diannamezi p {
  font-size: 50px;
}
.diannamezi span {
  font-size: 30px;
}
.xiangqingcase {
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
}
.xiangqingcase span {
  padding-left: 25px;
}
</style>
