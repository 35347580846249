// 中文
import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/components/home";
import About from "@/page/china/about/about";
import ProductCenter from "@/page/china/productCenter/productCenter";
import ProductDtails from "@/page/china/productCenter/productDtails";
import brandDetails from "@/page/china/productCenter/brandDetails";
import ChristophBrand from "@/page/china/productCenter/christophBrand";
import Case from "@/page/china/case/case";
import CaseDetails from "@/page/china/case/caseDetails";
import Contact from "@/page/china/contact/contact";
import Quality from "@/page/china/qualityAndService/quality";
import ChristophSpace from "@/page/china/productCenter/christophSpace";
import SpaceDetails from "@/page/china/productCenter/spaceDetails";
import Doorwallsystem from "@/page/china/productCenter/doorwallsystem";
import AllList from "@/page/china/about/allList";
import NewDetails from "@/page/china/about/newDetails";
import AndService from "@/page/china/qualityAndService/andService";
import general from "@/page/china/engineer/general";
import Mapdisplay from "@/page/china/engineer/distributed";
import download from "@/page/china/xiazai/download.vue";

import M_home from "@/mpages/china/m_home/m_home";
import M_about from "@/mpages/china/m_about/m_about";
import M_brandConcept from "@/mpages/china/m_about/m_brandConcept";
import mEnterpriseDynamics from "@/mpages/china/m_about/mEnterpriseDynamics";
import mProductCenter from "@/mpages/china/mProductCenter/mProductCenter";
import mQuality from "@/mpages/china/mQualityAndService/mQuality";
import mAndService from "@/mpages/china/mQualityAndService/mAndService";
import mCase from "@/mpages/china/mCase/mCase";
import mShopCase from "@/mpages/china/mCase/mShopCase";
import mCdetail from "@/mpages/china/mCase/mCdetail";
import mcontact from "@/mpages/china/mcontact/mcontact";
import mdoorwallsystem from "@/mpages/china/mProductCenter/mdoorwallsystem";
import mchristophBrand from "@/mpages/china/mProductCenter/mchristophBrand";
import mproductDtails from "@/mpages/china/mProductCenter/mproductDtails";
import mbrandDetails from "@/mpages/china/mProductCenter/mbrandDetails";
import mnewsList from "@/mpages/china/m_about/mnewsList";
import mnewDetails from "@/mpages/china/m_about/mnewDetails";
import wxmcontact from "@/mpages/china/mcontact/wxmcontact";
import mmapsearch from "@/mpages/china/mcontact/mmapsearch";
import mbrief from "@/mpages/china/m_brief/m_brief.vue";
import mmapserch from "@/mpages/china/m_mapserch/m_mapserch.vue";

// 英文
import EnHome from "@/components/enHome";
import EnAbout from "@/page/en/about/about";
import EnProductCenter from "@/page/en/productCenter/productCenter";
import EnProductDtails from "@/page/en/productCenter/productDtails";
import EnChristophBrand from "@/page/en/productCenter/christophBrand";
import EnCase from "@/page/en/case/case";
import EnCaseDetails from "@/page/en/case/caseDetails";
import EnContact from "@/page/en/contact/contact";
import EnQuality from "@/page/en/qualityAndService/quality";
import EnChristophSpace from "@/page/en/productCenter/christophSpace";
import EnSpaceDetails from "@/page/en/productCenter/spaceDetails";
import EnDoorwallsystem from "@/page/en/productCenter/doorwallsystem";
import EnAllList from "@/page/en/about/allList";
import EnNewDetails from "@/page/en/about/newDetails";
import EnAndService from "@/page/en/qualityAndService/andService";
import Engeneral from "@/page/en/engineer/general";
import EnMapdisplay from "@/page/en/engineer/distributed";
import Endownload from "@/page/en/xiazai/download.vue";

import EnM_home from "@/mpages/en/m_home/m_home";
import EnM_about from "@/mpages/en/m_about/m_about";
import EnM_brandConcept from "@/mpages/en/m_about/m_brandConcept";
import EnmEnterpriseDynamics from "@/mpages/en/m_about/mEnterpriseDynamics";
import EnmProductCenter from "@/mpages/en/mProductCenter/mProductCenter";
import EnmQuality from "@/mpages/en/mQualityAndService/mQuality";
import EnmAndService from "@/mpages/en/mQualityAndService/mAndService";
import EnmCase from "@/mpages/en/mCase/mCase";
import EnmShopCase from "@/mpages/en/mCase/mShopCase";
import EnmCdetail from "@/mpages/en/mCase/mCdetail";
import Enmcontact from "@/mpages/en/mcontact/mcontact";
import Enmdoorwallsystem from "@/mpages/en/mProductCenter/mdoorwallsystem";
import EnmchristophBrand from "@/mpages/en/mProductCenter/mchristophBrand";
import EnmproductDtails from "@/mpages/en/mProductCenter/mproductDtails";
import EnmnewsList from "@/mpages/en/m_about/mnewsList";
import EnmnewDetails from "@/mpages/en/m_about/mnewDetails";
import Enwxmcontact from "@/mpages/en/mcontact/wxmcontact";
import Enmmapsearch from "@/mpages/en/mcontact/mmapsearch";
import Enmbrief from "@/mpages/en/m_brief/m_brief.vue";
import Enmmapserch from "@/mpages/en/m_mapserch/m_mapserch.vue";

// 意大利文
import ItHome from "@/components/itHome";
import ItAbout from "@/page/it/about/about";
import ItProductCenter from "@/page/it/productCenter/productCenter";
import ItProductDtails from "@/page/it/productCenter/productDtails";
import ItChristophBrand from "@/page/it/productCenter/christophBrand";
import ItCase from "@/page/it/case/case";
import ItCaseDetails from "@/page/it/case/caseDetails";
import ItContact from "@/page/it/contact/contact";
import ItQuality from "@/page/it/qualityAndService/quality";
import ItChristophSpace from "@/page/it/productCenter/christophSpace";
import ItSpaceDetails from "@/page/it/productCenter/spaceDetails";
import ItDoorwallsystem from "@/page/it/productCenter/doorwallsystem";
import ItAllList from "@/page/it/about/allList";
import ItNewDetails from "@/page/it/about/newDetails";
import ItAndService from "@/page/it/qualityAndService/andService";
import Itgeneral from "@/page/it/engineer/general";
import ItMapdisplay from "@/page/it/engineer/distributed";
import Itdownload from "@/page/it/xiazai/download.vue";

import ItM_home from "@/mpages/it/m_home/m_home";
import ItM_about from "@/mpages/it/m_about/m_about";
import ItM_brandConcept from "@/mpages/it/m_about/m_brandConcept";
import ItmEnterpriseDynamics from "@/mpages/it/m_about/mEnterpriseDynamics";
import ItmProductCenter from "@/mpages/it/mProductCenter/mProductCenter";
import ItmQuality from "@/mpages/it/mQualityAndService/mQuality";
import ItmAndService from "@/mpages/it/mQualityAndService/mAndService";
import ItmCase from "@/mpages/it/mCase/mCase";
import ItmShopCase from "@/mpages/it/mCase/mShopCase";
import ItmCdetail from "@/mpages/it/mCase/mCdetail";
import Itmcontact from "@/mpages/it/mcontact/mcontact";
import Itmdoorwallsystem from "@/mpages/it/mProductCenter/mdoorwallsystem";
import ItmchristophBrand from "@/mpages/it/mProductCenter/mchristophBrand";
import ItmproductDtails from "@/mpages/it/mProductCenter/mproductDtails";
import ItmnewsList from "@/mpages/it/m_about/mnewsList";
import ItmnewDetails from "@/mpages/it/m_about/mnewDetails";
import Itwxmcontact from "@/mpages/it/mcontact/wxmcontact";
import Itmmapsearch from "@/mpages/it/mcontact/mmapsearch";
import Itmbrief from "@/mpages/it/m_brief/m_brief.vue";
import Itmmapserch from "@/mpages/it/m_mapserch/m_mapserch.vue";

const routes = [
  // 中文
  {
    path: "/download",
    component: download,
    meta: {
      title: "下载",
    },
  },
  {
    path: "/",
    component: Home,
    meta: {
      title: "威法定制家居",
    },
  },
  {
    path: "/m_home",
    component: M_home,
    meta: {
      title: "威法定制家居",
    },
  },
  {
    path: "/about",
    component: About,
    meta: {
      title: "关于vifa",
    },
  },
  {
    path: "/mAbout",
    component: M_about,
    meta: {
      title: "关于vifa",
    },
  },
  {
    path: "/productCenter",
    component: ProductCenter,
    meta: {
      title: "产品中心",
    },
  },
  {
    path: "/mProductCenter",
    component: mProductCenter,
    meta: {
      title: "产品中心",
    },
  },
  {
    path: "/quality",
    component: Quality,
    meta: {
      title: "品质",
    },
  },
  {
    path: "/case",
    component: Case,
    meta: {
      title: "案例展示",
    },
  },
  {
    path: "/contact",
    component: Contact,
    meta: {
      title: "联系我们",
    },
  },
  {
    path: "/productDtails",
    component: ProductDtails,
  },
    {
    path: "/brandDetails",
    component: brandDetails,
  },
  {
    path: "/caseDetails",
    component: CaseDetails,
  },
  {
    path: "/homeProducts",
    component: ChristophBrand,
  },
  {
    path: "/christophSpace",
    component: ChristophSpace,
  },
  {
    path: "/spaceDetails",
    component: SpaceDetails,
  },
  {
    path: "/doorwallsystem",
    component: Doorwallsystem,
  },
  {
    path: "/allList",
    component: AllList,
  },
  {
    path: "/newDetails",
    component: NewDetails,
  },
  {
    path: "/andService",
    component: AndService,
    meta: {
      title: "服务",
    },
  },
  {
    path: "/mBrandConcept",
    component: M_brandConcept,
    meta: {
      title: "品牌简介",
    },
  },
  {
    path: "/mEnterpriseDynamics",
    component: mEnterpriseDynamics,
    meta: {
      title: "品牌简介",
    },
  },
  {
    path: "/mQuality",
    component: mQuality,
    meta: {
      title: "品质",
    },
  },
  {
    path: "/mAndService",
    component: mAndService,
    meta: {
      title: "服务",
    },
  },
  {
    path: "/mCase",
    component: mCase,
    meta: {
      title: "家装案例",
    },
  },
  {
    path: "/mShopCase",
    component: mShopCase,
    meta: {
      title: "店铺案例",
    },
  },
  {
    path: "/mCdetail",
    component: mCdetail,
    meta: {
      title: "案例详情",
    },
  },
  {
    path: "/mcontact",
    component: mcontact,
    meta: {
      title: "联系我们",
    },
  },
  {
    path: "/wxmcontact",
    component: wxmcontact,
    meta: {
      title: "联系我们",
    },
  },

  {
    path: "/mdoorwallsystem",
    component: mdoorwallsystem,
    meta: {
      title: "门墙系统",
    },
  },
  {
    path: "/mHomeProducts",
    component: mchristophBrand,
    meta: {
      title: "大家居",
    },
  },
  {
    path: "/mproductDtails",
    component: mproductDtails,
    meta: {
      title: "产品详情",
    },
  },  {
  path: "/mbrandDetails",
  component: mbrandDetails,
  meta: {
    title: "产品详情",
  },
 },
  {
    path: "/mnewsList",
    component: mnewsList,
    meta: {
      title: "列表",
    },
  },
  {
    path: "/mnewDetails",
    component: mnewDetails,
    meta: {
      title: "详情",
    },
  },
  {
    path: "/mmapsearch",
    component: mmapsearch,
    meta: {
      title: "详情",
    },
  },
  {
    path: "/general",
    component: general,
    meta: {
      title: "我的工程",
    },
  },
  {
    path: "/Mapdisplay",
    component: Mapdisplay,
    meta: {
      title: "经销商查询",
    },
  },
  {
    path: "/mbrief",
    component: mbrief,
    meta: {
      title: "工程案例",
    },
  },
  {
    path: "/mmapserch",
    component: mmapserch,
    meta: {
      title: "工程经销商查询",
    },
  },
  // 英文
  {
    path: "/En/download",
    component: Endownload,
    meta: {
      title: "download",
    },
  },
  {
    path: "/En",
    component: EnHome,
    meta: {
      title: "VIFA Weifa high-end customization",
    },
  },
  {
    path: "/En/m_home",
    component: EnM_home,
    meta: {
      title: "VIFA Weifa high-end customization",
    },
  },
  {
    path: "/En/about",
    component: EnAbout,
    meta: {
      title: "About VIFA",
    },
  },
  {
    path: "/En/mAbout",
    component: EnM_about,
    meta: {
      title: "About VIFA",
    },
  },
  {
    path: "/En/productCenter",
    component: EnProductCenter,
    meta: {
      title: " Product Center",
    },
  },
  {
    path: "/En/mProductCenter",
    component: EnmProductCenter,
    meta: {
      title: " Product Center",
    },
  },
  {
    path: "/En/quality",
    component: EnQuality,
    meta: {
      title: "Quality ",
    },
  },
  {
    path: "/En/case",
    component: EnCase,
    meta: {
      title: "Case Show",
    },
  },
  {
    path: "/En/contact",
    component: EnContact,
    meta: {
      title: " Contact Us",
    },
  },
  {
    path: "/En/productDtails",
    component: EnProductDtails,
  },
  {
    path: "/En/caseDetails",
    component: EnCaseDetails,
  },
  {
    path: "/En/christophBrand",
    component: EnChristophBrand,
  },
  {
    path: "/En/christophSpace",
    component: EnChristophSpace,
  },
  {
    path: "/En/spaceDetails",
    component: EnSpaceDetails,
  },
  {
    path: "/En/doorwallsystem",
    component: EnDoorwallsystem,
  },
  {
    path: "/En/allList",
    component: EnAllList,
  },
  {
    path: "/En/newDetails",
    component: EnNewDetails,
  },
  {
    path: "/En/andService",
    component: EnAndService,
    meta: {
      title: "Service",
    },
  },
  {
    path: "/En/mBrandConcept",
    component: EnM_brandConcept,
    meta: {
      title: "Brand introduction",
    },
  },
  {
    path: "/En/mEnterpriseDynamics",
    component: EnmEnterpriseDynamics,
    meta: {
      title: "Brand introduction",
    },
  },
  {
    path: "/En/mQuality",
    component: EnmQuality,
    meta: {
      title: "Quality ",
    },
  },
  {
    path: "/En/mAndService",
    component: EnmAndService,
    meta: {
      title: "service",
    },
  },
  {
    path: "/En/mCase",
    component: EnmCase,
    meta: {
      title: "Home decoration case",
    },
  },
  {
    path: "/En/mShopCase",
    component: EnmShopCase,
    meta: {
      title: "Store case",
    },
  },
  {
    path: "/En/mCdetail",
    component: EnmCdetail,
    meta: {
      title: "Case details",
    },
  },
  {
    path: "/En/mcontact",
    component: Enmcontact,
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/En/wxmcontact",
    component: Enwxmcontact,
    meta: {
      title: "Contact Us",
    },
  },

  {
    path: "/En/mdoorwallsystem",
    component: Enmdoorwallsystem,
    meta: {
      title: "Door and Wall System",
    },
  },
  {
    path: "/En/mchristophBrand",
    component: EnmchristophBrand,
    meta: {
      title: " Household Product",
    },
  },
  {
    path: "/En/mproductDtails",
    component: EnmproductDtails,
    meta: {
      title: "product details",
    },
  },
  {
    path: "/En/mnewsList",
    component: EnmnewsList,
    meta: {
      title: "tabulation",
    },
  },
  {
    path: "/En/mnewDetails",
    component: EnmnewDetails,
    meta: {
      title: "detailed ",
    },
  },
  {
    path: "/En/mmapsearch",
    component: Enmmapsearch,
    meta: {
      title: "detailed ",
    },
  },
  {
    path: "/En/general",
    component: Engeneral,
    meta: {
      title: "My project",
    },
  },
  {
    path: "/En/Mapdisplay",
    component: EnMapdisplay,
    meta: {
      title: "Dealer inquiry",
    },
  },
  {
    path: "/En/mbrief",
    component: Enmbrief,
    meta: {
      title: "Project case",
    },
  },
  {
    path: "/En/mmapserch",
    component: Enmmapserch,
    meta: {
      title: "Project dealer inquiry ",
    },
  },
  // 意大利文
  {
    path: "/It/download",
    component: Itdownload,
    meta: {
      title: "Scarica l’app",
    },
  },
  {
    path: "/It/",
    component: ItHome,
    meta: {
      title: "Personalizzazione di fascia alta VIFA Weifa",
    },
  },
  {
    path: "/It/m_home",
    component: ItM_home,
    meta: {
      title: "Personalizzazione di fascia alta VIFA Weifa",
    },
  },
  {
    path: "/It/about",
    component: ItAbout,
    meta: {
      title: "SRORIE DI VIFA",
    },
  },
  {
    path: "/It/mAbout",
    component: ItM_about,
    meta: {
      title: "SRORIE DI VIFA",
    },
  },
  {
    path: "/It/productCenter",
    component: ItProductCenter,
    meta: {
      title: "PRODOTTI",
    },
  },
  {
    path: "/It/mProductCenter",
    component: ItmProductCenter,
    meta: {
      title: "PRODOTTI",
    },
  },
  {
    path: "/It/quality",
    component: ItQuality,
    meta: {
      title: "QUALITÀ ",
    },
  },
  {
    path: "/It/case",
    component: ItCase,
    meta: {
      title: "PROGETTI",
    },
  },
  {
    path: "/It/contact",
    component: ItContact,
    meta: {
      title: "CONTATTI",
    },
  },
  {
    path: "/It/productDtails",
    component: ItProductDtails,
  },
  {
    path: "/It/caseDetails",
    component: ItCaseDetails,
  },
  {
    path: "/It/christophBrand",
    component: ItChristophBrand,
  },
  {
    path: "/It/christophSpace",
    component: ItChristophSpace,
  },
  {
    path: "/It/spaceDetails",
    component: ItSpaceDetails,
  },
  {
    path: "/It/doorwallsystem",
    component: ItDoorwallsystem,
  },
  {
    path: "/It/allList",
    component: ItAllList,
  },
  {
    path: "/It/newDetails",
    component: ItNewDetails,
  },
  {
    path: "/It/andService",
    component: ItAndService,
    meta: {
      title: "SERVIZI",
    },
  },
  {
    path: "/It/mBrandConcept",
    component: ItM_brandConcept,
    meta: {
      title: "Presentazione del marchio",
    },
  },
  {
    path: "/It/mEnterpriseDynamics",
    component: ItmEnterpriseDynamics,
    meta: {
      title: "Presentazione del marchio",
    },
  },
  {
    path: "/It/mQuality",
    component: ItmQuality,
    meta: {
      title: "Qualità ",
    },
  },
  {
    path: "/It/mAndService",
    component: ItmAndService,
    meta: {
      title: "Servizio",
    },
  },
  {
    path: "/It/mCase",
    component: ItmCase,
    meta: {
      title: " Esempi di arredamento domestico",
    },
  },
  {
    path: "/It/mShopCase",
    component: ItmShopCase,
    meta: {
      title: "Esposizione del negozio",
    },
  },
  {
    path: "/It/mCdetail",
    component: ItmCdetail,
    meta: {
      title: "Dettagli del caso",
    },
  },
  {
    path: "/It/mcontact",
    component: Itmcontact,
    meta: {
      title: "Contattaci",
    },
  },
  {
    path: "/It/wxmcontact",
    component: Itwxmcontact,
    meta: {
      title: "Contattaci",
    },
  },

  {
    path: "/It/mdoorwallsystem",
    component: Itmdoorwallsystem,
    meta: {
      title: "Parete e Porta",
    },
  },
  {
    path: "/It/mchristophBrand",
    component: ItmchristophBrand,
    meta: {
      title: "Illuminazione e Mobili",
    },
  },
  {
    path: "/It/mproductDtails",
    component: ItmproductDtails,
    meta: {
      title: "Dettagli del prodotto",
    },
  },
  {
    path: "/It/mnewsList",
    component: ItmnewsList,
    meta: {
      title: "elenco",
    },
  },
  {
    path: "/It/mnewDetails",
    component: ItmnewDetails,
    meta: {
      title: "dettagli",
    },
  },
  {
    path: "/It/mmapsearch",
    component: Itmmapsearch,
    meta: {
      title: "dettagli",
    },
  },
  {
    path: "/It/general",
    component: Itgeneral,
    meta: {
      title: "Il mio progetto",
    },
  },
  {
    path: "/It/Mapdisplay",
    component: ItMapdisplay,
    meta: {
      title: "Ricerca del rivenditore di ingegneria",
    },
  },
  {
    path: "/It/mbrief",
    component: Itmbrief,
    meta: {
      title: "Caso di progetto",
    },
  },
  {
    path: "/It/mmapserch",
    component: Itmmapserch,
    meta: {
      title: "Ricerca del rivenditore di ingegneria",
    },
  },
];

const router = createRouter({
  // mode:history,
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.fullPath == "/wxmcontact") {
    setTimeout(() => {
      document.body.scrollTop = 900;
      document.documentElement.scrollTop = 900;
    }, 1000);
  } else {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
