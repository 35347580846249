<template>
  <div class="footer">
    <div class="footerCenten">
      <div class="footerLeft">
        <div class="logo">
          <img src="../common/image/footerLogo1.png" alt="" />
        </div>
        <div v-if="language == 1">
          <p>{{ contactus.companyName }}</p>
          <p>运营中心地址：{{ contactus.companyAddress }}</p>
          <p>生产基地：{{ contactus.producebaseAddress }}</p>
          <p>全国统一服务热线：{{ contactus.hotLine }}</p>
        </div>
        <div v-if="language == 2">
          <p>{{ contactus.companyNameEng }}</p>
          <p>Address of operation center：{{ contactus.companyAddressEng }}</p>
          <p>Production base：{{ contactus.producebaseAddressEng }}</p>
          <p>National service hotline：{{ contactus.hotLine }}</p>
        </div>
        <div v-if="language == 3">
          <p>{{ contactus.companyNameIta }}</p>
          <p>Indirizzo del centro operativo：{{ contactus.companyAddressIta }}</p>
          <p>Base di produzione：{{ contactus.producebaseAddressIta }}</p>
          <p>Linea telefonica nazionale：{{ contactus.hotLine }}</p>
        </div>

        <!-- <div class="bigqrcode" v-for="(item, index) in qrcodeData" :key="index" v-show="isqrcode == index"><img :src=item.qrcode alt=""  @mouseenter="qrcodeOn(index)" @mouseleave="qrcodeOut"></div> -->
        <ul>
          <li v-for="(item, index) in qrcodeData" :key="index" :class="{ mousqrcode: isqrcode == index }"
            @mouseenter="qrcodeOn(index)" @mouseleave="qrcodeOut">
            <img class="qicon" :src="item.qrcodeicon" alt="" />
            <img class="qimg" :src="'https://www.vifa.cn' + item.qrcode" alt="" />
          </li>
        </ul>
      </div>
      <div class="footerRight pwjpostion">
        <ul v-if="language == 1" class="chUi">
          <!-- :class="{navActive:isnv == index}" -->
          <li v-for="(item, index) in footnavData" :key="index" :class="['aligin', { navActive: isnv == index }]"
            @mouseenter="footnavOver(index)" @mouseleave="footnavOut" @click="tolike(index)">
            <!--      -->
            <span></span>{{ item.age }}
            <div class="footerzk">
              <p @click="routerto(footerindex,index)" v-for="(footeritem, footerindex) in item.footerzk" :key="footerindex"
                class="wrp">
                {{ footeritem.p }}

              </p>
              <!-- <router-link @click="routerto(index)" v-for="(footeritem, footerindex) in item.footerzk" :key="footerindex" :to="footeritem.link"><p>{{footeritem.p}}</p></router-link>
                            <p>智能云设计</p>
                            <p>威法学院</p>
                            <p>vifa供应链</p> -->
            </div>
          </li>
        </ul>
        <ul v-if="language == 2" class="enUi">
          <!-- :class="{navActive:isnv == index}" -->
          <li v-for="(item, index) in footnavDataE" :key="index" :class="['aligin ', { navActive: isnv == index }]"
            @mouseenter="footnavOver(index)" @mouseleave="footnavOut" @click="tolike(index)">
            <span></span>{{ item.age }}
            <div class="footerzk">
              <p @click="routerto(footerindex,index)" v-for="(footeritem, footerindex) in item.footerzk" :key="footerindex"
                class="wrp">
                {{ footeritem.p }}

              </p>
              <!-- <router-link @click="routerto(index)" v-for="(footeritem, footerindex) in item.footerzk" :key="footerindex" :to="footeritem.link"><p>{{footeritem.p}}</p></router-link>
                            <p>智能云设计</p>
                            <p>威法学院</p>
                            <p>vifa供应链</p> -->
            </div>
          </li>
        </ul>
        <ul v-if="language == 3" class="itUi">
          <!-- :class="{navActive:isnv == index}" -->
          <li v-for="(item, index) in footnavDataI" :key="index" :class="['aligin ', { navActive: isnv == index }]"
            @mouseenter="footnavOver(index)" @mouseleave="footnavOut" @click="tolike(index)">
            <span></span>
            <strong>{{ item.age }}</strong>
            <div class="footerzk">
              <p @click="routerto(footerindex,index)" v-for="(footeritem, footerindex) in item.footerzk" :key="footerindex"
                class="wrp">
                {{ footeritem.p }}

              </p>
              <!-- <router-link @click="routerto(index)" v-for="(footeritem, footerindex) in item.footerzk" :key="footerindex" :to="footeritem.link"><p>{{footeritem.p}}</p></router-link>
                            <p>智能云设计</p>
                            <p>威法学院</p>
                            <p>vifa供应链</p> -->
            </div>
          </li>
        </ul>
        <!-- <button
          class="SmartHome"
          @click="shouxiazai"

        >
          <img src="./img/btn9.png" alt="" />
        </button> -->
      </div>
    </div>
    <div class="footerCenten alignItems">
      <div class="footerLeft">
        <p v-if="language == 1">
          <span @click="beian">粤ICP备16027141号</span>, All Rights Reserved
          {{ contactus.companyName }}
        </p>
        <p v-if="language == 2">
          <span @click="beian">Y.ICP.B No.16027141</span>,All Rights
          Reserved
          {{ contactus.companyNameEng }}
        </p>
        <p v-if="language == 3">
          <span @click="beian">Guangdong ICP n.16027141,</span>All Rights
          Reserved
          {{ contactus.companyNameIta }}
        </p>
      </div>
      <div class="footerRight">
        <button class="SmartHome" @click="shouxiazai">
          <img src="../common/image/smarthome5.png" alt="" />
        </button>
        <!-- <p style="font-size: 18px">威法智能App下载</p> -->
        <!-- <p>威法智能App下载</p> -->
        <!-- <p>From Denmark，Since 1933</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import weixin from "../common/image/weixin.png";
import xiaochengxu from "../common/image/xiaochengxu.png";
import douyin from "../common/image/douyin.png";
import xiaohongshu from "../common/image/xiaohongshu.png";
import { getHomePage } from "../api/api";

export default {
  name: "footer",
  props: ["isxiazai", "language","istongzhi"],
  data() {
    return {
      footerxiazai: false,
      footertongzhi: false,
      footnavData: [
        {
          age: "应用门户",
          footerzk: [
            { p: "新CRM", link: "design.html" },
            //   {p:'威法学院',link:'Signin/#/login'},
            { p: "vifa学习库", link: "login" },
            { p: "智能云设计", link: "design.html" },


            // { p: "vifa云学习", link: "login" },
            // { p: "vifa图库", link: "login" },
            { p: "vifa供应链", link: "login" },
            // { p: "智能云设计", link: "design.html" },
            //{ p: "CRM", link: "login" },
          ],
        },
        //注销
        { age: "其他项目", footerzk: [
            { p: "工程项目", link: "general.html" },
            { p: "威法实验室", link: "login" },
          ] },
        { age: "联系方式", footerzk: [] },
        { age: "通知声明", footerzk: [] },
      ],
      footnavDataI: [
        {
          age: "Portale delle applicazioni",
          footerzk: [
            { p: "New CRM", link: "design.html" },
            { p: "vifa Learning Library", link: "login" },
            { p: "Progettazione Cloud intelligente", link: "design.html" },
            { p: "vifa Catena di fornitura", link: "login" },
            { p: "CRM", link: "login" },
            //   {p:'威法学院',link:'Signin/#/login'},
            // { p: "vifa Apprendimento Cloud", link: "login" },
            // { p: "vifa Galleria", link: "login" },
            // { p: "vifa Catena di fornitura", link: "login" },
            // { p: " Progettazione Cloud intelligente", link: "design.html" },
            // { p: "CRM", link: "login" },
          ],
        },
        //注销
        { age: "Progetto di ingegneria", footerzk: [] },
        { age: "Informazioni sui contatti", footerzk: [] },
        { age: "Dichiarazione di avviso", footerzk: [] },
      ],
      footnavDataE: [
        {
          age: "Application portal",
          footerzk: [
            { p: "nuovo CRM", link: "design.html" },
            { p: "vifa Learning Library", link: "login" },
            { p: "Intelligent cloud design", link: "design.html" },
            { p: "vifa supply chain", link: "login" },
            { p: "CRM", link: "login" },
            //   {p:'威法学院',link:'Signin/#/login'},
            // { p: "vifa cloud learning", link: "login" },
            // { p: " vifa photo", link: "login" },
            // { p: "vifa supply chain", link: "login" },
            // { p: "Intelligent cloud design", link: "design.html" },
            // { p: "CRM", link: "login" },
          ],
        },
        //注销
        { age: "Project", footerzk: [] },
        { age: "Contact", footerzk: [] },
        { age: "Notification", footerzk: [] },
      ],
      isnv: 99999,
      qrcodeData: [
        {
          //   qrcode:qrcode2,
          qrcodeicon: weixin,
          qrcode: ''
        },
        {
          //   qrcode:qrcode1,
          qrcodeicon: xiaochengxu,
          qrcode: ''
        },
        {
          //   qrcode:qrcode4,
          qrcodeicon: douyin,
          qrcode: ''
        },
        {
          //   qrcode:qrcode3,
          qrcodeicon: xiaohongshu,
          qrcode: ''
        },
      ],
      isqrcode: 999,
      //url1: 'http://www.vifa.cn/manage/login.html',
      url1: "https://wx.vifa.cn/design.html ",
      url2: "http://v.vifa.cn/login.html",
      //url3: "https://console.xiekeyun.com/login",
      url3: "https://yun.vifa.cn/login",
      url4: "https://serve.vifa.cn/gallery/",
      url5: "http://crm.vifa.cn/",
      url6: "https://gallery.vifa.cn/gallery/pc/#/login",
      url7: "http://scrm.vifa.cn/chsm/coframe/auth/login/login.jsp",
      contactus: {},
    };
  },
  mounted() {
    this.getPageData();
    // console.log(this.footerxiazai)
    this.footerxiazai = this.isxiazai;
    this.footertongzhi = this.istongzhi;
  },
  methods: {
    shouxiazai() {
      console.log(this.footerxiazai, 1111);
      this.footerxiazai = true;
      this.$emit("footerxiazai", this.footerxiazai);
    },
    shoutongzhi() {
      this.footertongzhi = true;
      this.$emit("footertongzhi", this.footertongzhi);
    },
    getPageData() {
      getHomePage()
        .then((res) => {
          // console.log(res)
          this.contactus = res.contactus;
          this.qrcodeData = [
            {
              qrcode: res.contactus.gzhImage,
              qrcodeicon: weixin,
            },
            {
              qrcode: res.contactus.xcxImage,
              qrcodeicon: xiaochengxu,
            },
            {
              qrcode: res.contactus.dyImage,
              qrcodeicon: douyin,
            },
            {
              qrcode: res.contactus.xhsImage,
              qrcodeicon: xiaohongshu,
            },
          ];
        })
        .catch((res) => {
          console.log(res);
        });
    },
    footnavOver(index) {
      this.isnv = index;
    },
    footnavOut() {
      this.isnv = 99999;
    },
    qrcodeOn(index) {
      this.isqrcode = index;
    },
    qrcodeOut() {
      this.isqrcode = 999;
    },
    routerto(index,index_i) {
      if(index_i==0){
        if (index == 0) {
          window.open(this.url7, "_blank");
        } else if (index == 1) {
          window.open('https://gallery.vifa.cn/', '_blank')
        } else if (index == 2) {
          window.open(this.url1, "_blank");
        } else if (index == 3) {
          window.open(this.url3, "_blank");
        } else if (index == 4) {
          window.open(this.url5, "_blank");
        }
      }
      if(index_i==1){
        if (index == 0) {
          this.$router.push("/general");
        } else if (index == 1) {
          window.open('https://www.vifa-laboratory.com/', '_blank')
        }
      }



    },
    tolike(index) {
      // if (index == 1) {
      //   this.$router.push("/general");
      // }
      if (index == 2) {
        this.$router.push("/contact");
      }
      if (index == 3) {
        this.shoutongzhi()
      }
    },
    beian() {
      window.open("https://beian.miit.gov.cn", "_blank");
    },
  },
};
</script>

<style>
.footer {
  width: 100%;
  background: #8d847f;
  padding: 105px 0 90px;
  color: #ffffffcc;
}

.footer p {
  line-height: 30px;
  color: #fff;

}

.footerCenten {
  max-width: 93rem;
  min-width: 60rem;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.footerLeft {
  flex: 1 50%;
  text-align: left;
  position: relative;
}

.footerLeft .logo {
  padding-bottom: 45px;
}

.footerLeft ul {
  display: flex;
  padding-top: 25px;
  padding-bottom: 15px;
  width: 400px;
  height: 100px;
  align-items: center;
}

.footerLeft ul li {
  flex: 1;
  text-align: center;
}

.footerLeft ul li p {
  text-align: center;
  padding-top: 10px;
}

.footerRight {
  flex: 1 50%;
  text-align: right;
}

.footerRight ul {
  font-weight: 500;
  display: flex;
  padding-top: 8px;
}

.footerRight ul li {
  flex: 1;
  /* line-height: 38px;
    padding-top: 35px; */
  cursor: pointer;
  position: relative;

  /* overflow: hidden; */
}

.footerRight ul li span {
  display: none;
}

/* chiak选择 */
.footerRight ul li.navActive span {
  width: 1px;
  height: 16px;
  display: inline-block;
  position: absolute;
  /*chiak改 left: 55px; */
  left: 35px;
  top: -16px;
  background: #f08518;
  display: block;
}

.footerRight ul li.navActive span {
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
}

@keyframes headerSpan {
  from {
    top: -16px;
  }

  to {
    top: 0;
  }
}

@-webkit-keyframes headerSpan {
  from {
    top: -16px;
  }

  to {
    top: 0;
  }
}

@-moz-keyframes headerSpan {
  from {
    top: -16px;
  }

  to {
    top: 0;
  }
}

@-o-keyframes headerSpan {
  from {
    top: -16px;
  }

  to {
    top: 0;
  }
}

.footerzk {
  margin-left: 2.188rem;
  border-left: 1px solid;
  text-align: left;
  padding-left: 30px;
  /* padding-left: 12%; */
  margin-top: 9%;
  display: none;

}

.footerRight ul li.navActive .footerzk {
  display: block;
}

.bigqrcode {
  padding-bottom: 40px;
  position: absolute;
  left: 100px;
  bottom: 80px;
}

.bigqrcode img {
  background: #fff;
  padding: 15px;
  width: 190px;
}

.footerLeft ul li img {
  margin: 0 auto;
}

.footerLeft ul li.mousqrcode .qicon {
  display: none;
}

.footerLeft ul li.mousqrcode .qimg {
  display: block;
  width: 90px;
  height: 90px;
  background: #fff;
  border-radius: 5px;
  padding: 5px;
}

.footerLeft ul li .qicon {
  display: block;
  width: 45px;
  height: 45px;
}

.footerLeft ul li:nth-child(1) .qicon {
  width: 56px;
}

.footerLeft ul li .qimg {
  display: none;
}

.footerRight .SmartHome {
  background: transparent;
}

.alignItems {
  align-items: center;
}

/* pwj文字居中 */
.aligin {
  text-align: center;
}

.pwjpostion {
  position: relative;
}

.SmartHome {
  /* margin-bottom: ; */
  position: absolute;
  right: 59.2px;
  bottom: 11.4px;
}

.SmartHome img {
  width: 188px;
  height: 46px;
}

.footerRight .enUi {
  min-height: 50px;
}


.footerRight .itUi {
  min-height: 50px;
}

.footerRight .enUi li.navActive span {
  left: 13px;
}

.enUi .footerzk {
  margin-left: 13px;
  white-space: nowrap;
}

.footerRight ul li strong {
  width: 120px;
  display: inline-block;
}

.chUi {
  min-height: 50px;
}

.footerRight .itUi li.navActive span {
  height: 2rem;
}

.smalll_font {
  white-space: nowrap;
  font-size: 12px
}


.wrp {
  width: 100px;
  white-space: nowrap;
}
</style>
