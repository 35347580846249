<template>
    <div class="mhome">
        <div class=" homelogohead">
            <ul  class="kalajinxcccc-m " style="display: block;position: relative;" >
                <li :style="'background-image:url('+'https://www.vifa.cn'+productDetailsImage+')'">
                    <div  class="toleft"  @click="hmqzsr">
                        <img  :src="ishmqzsl? 'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/rightbb.png':'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/botton.png'"/>
                    </div>
                    <div class="toright" @click="hmqzsl">
                        <img :src="ishmqzsr?'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/leftbb.png':'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/left.png'"/>
                    </div>
                    <div class="img" >
                        <img @click="mdshow(index)"   v-for="(item,index) in productDetails" :key="index" :style=" index==detislindex ? ' border: 1px solid gray;':'   filter: brightness(50%);'" :src="'https://www.vifa.cn'+item"/>
                    </div>
                  </li>
            </ul>
        </div>

        <div>
            <div class="pdtitle">{{ product.name }}</div>
            <div class="shuoming hometopswipt">
                <div class="pinpailishi">
                    <div class="pplstext">
                        <div class="title">{{ product.tags }}</div>
                        <p class="bot20" style="text-align: center; border-bottom:  1px solid  #DEDEDE;width: 70%"></p>
                        <p>
                            <span style="font-weight: 800" v-if="product.category == 'dining' || product.category == 'light' ">指定材质：</span>
                            <span style="font-weight: 800" v-else>指定款色：</span>
                            {{ product.surfaceMaterial }}
                        </p>

                    </div>
                </div>
            </div>
          <div class="cpxjbut">
            <ul>
              <li>
                   <img :src="'https://www.vifa.cn'+product.sideImage">
                  <p class="top20"><span class="fontSize28 " style="font-weight: 800">一口价:￥{{product.currentPrice}}</span><span style="margin-left: 20px; font-weight: 800">原价:</span><span style="font-weight: 300">￥</span><span style="text-decoration: line-through; font-weight: 300 ">{{ product.originalPrice}}</span> </p>
                  <router-link :to="{path:'/contact'}"><div class="info-btn">更多产品信息请联系您所在当地经销商</div></router-link>

              </li>
            </ul>
          </div>
          <div class="hometopswipt" style="margin-top: 15px">
            <div class="homelogos"  v-for="(item) in displayImagesList">
              <img :src="'https://www.vifa.cn' + item">
            </div>
          </div>

        </div>
        <div class="founder"  >
            <div class="top20 productInfo">
              <!--<span class="fontSize24"  style="font-weight: 800;">产品信息</span>-->
            </div>
            <ul class="kalajinx-info-m"
                v-for="(item, index) in productInfoData"
                :key="index" @click="spanceInfo(index)"
            >
              <li>
                <div class="info">{{item.name}}<img  :src="isInfoActiv == index ? 'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/right.png':'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/botton.png'"></div>
                <div class="des" v-if="isInfoActiv == index">
                  <p class="p" style="text-align: left">{{item.content}}</p>
                </div>
              </li>
            </ul>
            <div style="font-weight: 300;" class="top80"> <span  class="fontSize22"> 相关产品/RELATED PRODUCTS </span></div>

        </div>
        <div >
            <ul class="kalajinxsss-m bot30 " >
              <div v-for="(item, index) in randomProductList" :key="index" >
                <li  v-if="item.id!=produceId" @click="toProductDetailsData(item.id)">
                  <img :src="'https://www.vifa.cn'+item.mainImage"  />
                  <span class="fontSize24" style="font-weight: 500;">{{ item.name }}</span>
                </li>
              </div>

            </ul>
        </div>
        <div style="clear: both"></div>

    </div>
</template>

<script>
import {getProductLightDetails,getRandomProductlight} from '@/api/api'

export default {
  data(){
    return{
      product:{},
      productDetails:[],
      displayImagesList:[],
      randomProductList:[],
      productDetailsImage:"",
      productListDetails:[],
      productInfoData: [],
      produceId: 0,
      isInfoActiv: 0,
        ishmqzsl: false,
        ishmqzsr: true,
        detislindex:0,
        ismdshow: 0,
    }
  },
  mounted(){
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths+'/mbrandDetails?id='+this.$route.query.id);
    } else {
      this.$router.replace('/brandDetails?id='+this.$route.query.id);
    }
    this.getProductDetailsData(this.$route.query.id);
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },

      mdshow(index) {
          this.productDetailsImage = this.productDetails[index];
          this.detislindex = index;
          if (this.ismdshow == 0) {
              this.ishmqzsl = false;
              this.ishmqzsr = true;
          } else if (this.ismdshow == this.productDetails.length - 1) {
              this.ishmqzsl = true;
              this.ishmqzsr = false;
          } else {
              this.ishmqzsl = false;
              this.ishmqzsr = false;
          }
      },
      hmqzsl() {
          if (this.ismdshow > 0) {
              this.mdshow(--this.ismdshow);
          }
      },
      hmqzsr() {
          if (this.ismdshow < this.productDetails.length - 1) {
              this.mdshow(++this.ismdshow);
          }
      },
    spanceInfo(index) {
      this.isInfoActiv = index;
    },
     getRandomProductlight() {
          getRandomProductlight().then(res => {
              this.randomProductList = res.lightProduce;
       })
      },
      toProductDetailsData(produceId){
          window.scrollTo(0, 0);
          this.getProductDetailsData(produceId)
      },
    getProductDetailsData(produceId) {
        this.produceId=produceId;
        this.productInfoData=[];
        this.productDetails=[];
        this.displayImagesList=[];
        let data = { produceId:produceId}
        getProductLightDetails(data).then(res => {
        this.product = res.lightProduce;
        this.productDetails=res.lightProduce.detailImages.split("|")
        this.productDetailsImage=this.productDetails[0]
        this.displayImagesList=res.lightProduce.displayImages.split("|")
        this.productInfoData.push({
          name:'产品描述',
          content:res.lightProduce.description
        })
        this.productInfoData.push({
          name:'细节艺术',
          content:res.lightProduce.details
        })
        this.productInfoData.push({
          name:'购物须知',
          content:res.lightProduce.deliveryPolicy
        })
      })
       this.getRandomProductlight()
    }
  }
}
</script>

<style scoped>
.homelogo{
    width: 560px;
    margin: 0 auto 18px;
}
.homelogo img{
    width: 100%;
    height: 500px;
    object-fit: cover;
}
.homelogohead{
    width: 750px;
}
.xiaotop{
    width: 1px;
    height: 34px;
    background: #f08518;
    margin: 35px auto 0;
}
.pplstext{
    line-height: 1.8;
    width: 670px;
    margin: 0 auto;
    text-align: center;
}
.pplstext p{
    font-size: 12px;
    margin: 0 auto;
}
.pplstext img{
  width: 80%;
  margin-right: 18%;
}
.info-btn{
    width: 60%;text-align: center;
    padding: 18px;
    color: white;
    background-color: black;
    margin: 30px auto;
}
.pinpailishi{
    position: relative;
}
.pinpailishi .title{
    font-size: 45px;
    padding-top: 30px;
}
.shuoming .title{
    font-size: 26px;
    font-weight: 500;
    padding-top: 0;
    margin-top: 10px;

}
.cpxjbut{
    margin-top: 5px;
}
.pinpailishi .subtitle{
    font-size: 30px;
}
.pinpailishi p{
    font-size: 22px;
    padding-bottom: 10px;
    font-weight: 500;
    margin-top: 10px;
}
.pplsimg{
    width: 100%;
    height: 492px;
    overflow: hidden;
}
.pinpailishi ul{
    display: flex;
    margin-left: -247.5px;
}
.pinpailishi ul li{
    width: 375px;
    height: 492px;
    flex: 1;
    padding: 0 20px;
}
.pinpailishi ul li img{
    width: 375px;
    height: 492px;
}

.bottomtitle{
    width: 670px;
    margin: 0 auto;
    color: #f08518;
    font-size: 30px;
    line-height: 1.5;
    position: relative;
    position: absolute;
    left: 40px;
    top: 50%;
    margin-top: -56.25px;
}
.bottomtitle .showtitle p{
    text-align: center;
}
.bottomtitle .showtitle div{
    font-size: 45px;
}
.bottomtitle .toleft{
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 56.25px;
}
.bottomtitle .toright{
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 56.25px;
}
.bottomtitle .toleft span{
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    left: 10px;
    top: -9px;
}
.bottomtitle .toright span{
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    right: 10px;
    top: -9px;
}
.pinpailishiani-move{
    transition: all .3s;
}

.quanpin .bottomtitle{
    color: #fff;
}
.quanpin .pplsimg{
    height: 447px;
}
.quanpin .pinpailishi ul{
    width: 2250px;
    margin: 0;
}
.quanpin .pinpailishi ul li{
    width: 750px;
    height: 447px;
    padding: 0;
}
.quanpin .pinpailishi ul li img{
    width: 750px;
    height: 447px;
}


.input{
    font-size: 26px;
    width: 670px;
    margin: 40px auto;
}
.input input{
    width: 100%;
    line-height: 2;
    border: 1px solid #4c413d;
    text-align: center;
}
.producttiel{
    background: #d2cdca;
    padding-top: 1px;
    padding-bottom: 40px;
}
.subnav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
}
.subnav ul{
    width: 100%;
    display: flex;
    /* padding-top: 20px; */
    flex-wrap: wrap;
    background: #000000cc;
    color: #fff;
}
.subnav>div{
    background: #000000cc;
    color: #fff;
}
.subnav>div.isshow{
    width: 350px;
    height: 0;
    margin: 0  auto;
}
.subnav ul li{
    flex: 1 187.5px;
    text-align: center;
    font-size: 26px;
    line-height: 2;
    margin-bottom: 20px;
    padding-top: 30px;
}
.subnav ul li.avtive{
    color: #f08518;
}
.subnav ul li p{}
.subnav ul li img{
    width: 71px;
    height: 55px;
    margin: 0 auto;
}
.subnav ul li.avtive .icon{
    display: none;
}
.subnav ul li.avtive .icon_active{
    display: block;
}
.subnav ul li .icon_active{
    display: none;
}
.subnav ul li .icon{
    display: block;
}
.productcont{
    padding-bottom: 40px;
    line-height: 2;
    width: 750px;
    text-align: center;
}
.productcont img{
    width: 100%;
}
.productcont p{
    font-size: 26px;
    line-height: 88px;
}
.productcont div{
    font-size: 26px;
    width: 225px;
    margin: 0 auto;
    line-height: 1;
    border: 1px solid #4c413d;
    line-height: 65px;
}
.avtive{
    color: #f08518;
    border-color: #f08518;
}
.cpxjbut ul{
    width: 650px;
    margin: 0 auto;
    justify-content: space-between;
    overflow:hidden;
}
.cpxjbut ul li{
    text-align: center;
    font-weight: 500;
    font-size: 20px;
}
.cpxjbut ul li img{
    width: 80%;
    object-fit: cover;
}
.cpxjbut ul li.qtiele{
    border: none;
    font-size: 40px;
    text-align: left;
    float: left;
    width: 342px;
}
.cpxitu{
    width: 100%;
    padding-bottom: 40px;
    padding-top: 20px;
}
.cpxitu img{
    width: 100%;
    object-fit: cover;
    margin-top: 20px;
}
.cfkjystitle{
    width: 100%;
    position: relative;
    line-height: 41px;
}
.cfkjystitle p{
    width: 100%;
    border-bottom: 1px solid #000;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -.5px;
    z-index: -22;
}
.cfkjystitle div{
    background: #fff;
    margin: 0 auto;
    display: inline-block;
    padding: 0 50px;
}
.yanse>ul{
    padding: 20px 0;
    font-size: 18px;
}
.yanse{}
.yanse ul{
    width: 750px;
    display: flex;
    flex-wrap: wrap;
}
.yanse ul li{
    width: 105px;
    padding: 10px;
}
.yanse ul li img{
    width: 105px;
    height: 105px;
}
.yanse ul li p{
    padding: 10px 0;
    font-size: 22px;
}
.cfkjcont ul li{
    flex: none;
}
.cfkjcont{
    width: 750px;
}
.mask{
    width: 100%;
    height: 100%;
    background: #00000069;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
}
.hometopswipt{
    padding-bottom: 30px;
    width: 100%;

}
::-webkit-scrollbar{      display:none    }
.hometopswipt .homelogo{
    margin-bottom: 0;
}
.yanse>div{
    padding-bottom: 100px;
}
.homelogos{
    padding:  0px 18px 0px 18px;
}
.homelogos img{
    width: 100%;
}

.pdtitle{
    margin-top: 35px;
    font-weight: 800;
    font-size: 32px;
    line-height: 1.8;
    text-align: center;
}
</style>
