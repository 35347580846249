
<template>
    <div class="livingRoomWap kitchenDetails">
        <div class="livingRoomSpace">
<!--            <div :class="{ jianbianbanner: iscaseBaner }" ref="caseBaner">-->
<!--                <div class="chufangxbanner" style="margin: 0px auto;width: 100%">-->
<!--                    <img src='https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/1.jpg'  >-->
<!--                </div>-->
<!--            </div>-->
            <div class="productnav" style="background:black"  :class="{ productnavTop: isproductnavTop,  productnavTopaa: isproductnavTopaa, productnavTopbb: isproductnavTopbb,   productnavtopling: isproductnavtopling, jianbianbanner: iscaseBaner, }"   >
                <div class="space">
                    <div
                            :class="{ spaceActiv: isSpaceActiv == item.id }"
                            v-for="(item, index) in kjdzallData"
                            :key="index" @click="spance(index)" >
                        <img class="spaceimg" :src="item.icon" />
                        <img class="spaceimgActive" :src="item.icon_active" />
                        <p>{{ item.name }}</p>
                    </div>
                </div>
            </div>

            <div class="jianbianq" :class="{ jianbianh: issjmx }" ref="kjmx">
                <div class="founder mar-top" v-show="iscp">
                    <transition-group name="pinpailishiani " >
                        <ul  class="kalajinxcccc kalajinxcccc-left " >
                            <li :style="'background-image:url('+'https://www.vifa.cn'+productDetailsImage+')'">
                                <div  class="toleft"  @click="hmqzsr">
                                    <img  :src="ishmqzsl? 'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/rightbb.png':'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/botton.png'"/>
                                </div>
                                <div class="toright" @click="hmqzsl">
                                    <img :src="ishmqzsr?'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/leftbb.png':'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/left.png'"/>
                                </div>
                                <img @click="mdshow(index)"   v-for="(item,index) in productDetails" :key="index" :style=" index==detislindex ? ' border: 1px solid gray;':'   filter: brightness(50%);'" :src="'https://www.vifa.cn'+item"/>
                            </li>
                        </ul>
                    </transition-group>
                    <ul class="kalajinxcccc kalajinxcccc-right" >
                        <li>
                            <div style="margin-left: 20%" >
                                <p class="fontSize24" style="font-weight: 800;">{{ product.name }}</p>
                                <p class="fontSize16 bot20" style="font-weight: 500;">{{ product.tags }}</p>
                                <p class="bot20" style="border-bottom:  1px solid  #DEDEDE"></p>
                                <p class="top20" >
                                    <span class="fontSize18" style="font-weight: 800;" v-if="product.category == 'dining' || product.category == 'light' ">指定材质：</span>
                                    <span class="fontSize18" style="font-weight: 800;" v-else >指定款色：</span>
                                    <span class="fontSize16" style="font-weight: 500;">{{ product.surfaceMaterial }}</span></p>
                                <img :src="'https://www.vifa.cn'+product.sideImage">
                               <p class="bot20 fontSize16" ><span class="fontSize24" style="font-weight: 800;">一口价:</span><span class="fontSize24" style="font-weight: 800;">￥{{product.currentPrice}}</span><span  style="font-weight: 500; margin-left: 20px;">原价:</span><span style="font-weight: 500;">￥</span><span style="text-decoration: line-through; font-weight: 500">{{ product.originalPrice}}</span> </p>
                                <router-link :to="{path:'/contact'}"><div class="moreButInfo" style="font-weight: 500;">更多产品信息请联系您所在当地经销商</div></router-link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>


            <div class="jianbianq " :class="{ jianbianh: issjmx }" ref="kjmxss"  >
                <div class="founder" v-show="iscp" >
                    <div class="top20 productInfo">
                        <!--   <span  style="font-weight: 800;">产品信息</span>-->
                    </div>

                    <ul class="kalajinx-info"
                        v-for="(item, index) in productInfoData"
                        :key="index" @click="spanceInfo(index)"
                    >
                        <li>
                            <div class="info" style="font-weight: 500">{{item.name}}<img  :src="isInfoActiv == index ? 'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/right.png':'https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/botton.png'"></div>
                            <div class="des" v-if="isInfoActiv == index">
                                <p class="p" style="text-align: left">{{item.content}}</p>
                            </div>
                        </li>
                    </ul>
                    <!--                    <ul class="kalajinxsss bot20 " >
                                            <li>
                                                <img :src="'https://www.vifa.cn'+product.displayImages"  />
                                                <div class="dibu">
                                                    <p class="fontSize16" style="font-weight: 500;">{{ product.name }}</p>
                                                    <p class="fontSize16" style="font-weight: 500;">{{ product.tags }}</p>
                                                    <p class="fontSize14" >{{ product.advertise}}</p>
                                                </div>
                                            </li>
                                        </ul>-->

                    <ul class="kalajinxsss bot20 " style="width: 50% ;">
                        <li  v-for="(item) in displayImagesList" style="margin-top: 3px">
                            <img :src="'https://www.vifa.cn'+item "  />
                        </li>
                    </ul>

                    <div style="text-align: left;font-weight: 500;margin-top: 100px"> <span  class="fontSize14"> 相关产品/RELATED PRODUCTS </span></div>
                    <ul class="kalajinxsss-1" >
                      <div v-for="(item, index) in randomProductList" :key="index" >
                        <li  v-if="item.id!=produceId" @click="toProductDetailsData(item.id)" style="margin-right: 10px">
                          <img :src="'https://www.vifa.cn'+item.mainImage"  />
                          <div class="dibu">
                            <p >{{ item.name }}</p>
                          </div>
                        </li>
                      </div>

                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import sofa from '@/common/image/sofa.png'
import sofa_active from '@/common/image/sofa_active.png'
import recliners from '@/common/image/recliners.png'
import recliners_active from '@/common/image/recliners_active.png'
import dining from '@/common/image/dining.png'
import dining_active from '@/common/image/dining_active.png'
import bed from '@/common/image/bed1.png'
import bed_active from '@/common/image/bed_active1.png'
import light from '@/common/image/light.png'
import light_active from '@/common/image/light_active.png'
    import {getProductLightDetails,getRandomProductlight} from "@/api/api";

    export default {
        data() {
            return {
                displayImagesList:[],
                randomProductListrandomProductList:[],
                mdmarginLeft: 0,
                ismdshow: 0,
                produceId: 0,
                ishmqzsl: false,
                ishmqzsr: true,
                homeVifaAround:[],
                mdwidth:0,
                /*================================*/
                lastclick: "",
                kalajinxData: [],
                isdivshop: 999,
                kjdetailsnav: [{ name: "产品细节" }, { name: "推荐组合及推荐材料" }],
                iscp: true,
                isys: false,
                productbanner: "",
                ProductDetail: "",
                productName: "",
                iscaseBaner: false,
                isbrandConcepttitle: false,
                issjmx: false,
                issjmxxx: false,
                iskjmx: false,
                isproductnavTop: false,
                isproductnavTopaa: false,
                isproductnavTopbb: false,
                isproductnavtopling: false,
                isSpaceActiv: 0,
                isInfoActiv: 0,
                windwosHeight: 0,
                productInfoData: [],
                kjdzallData: [
                  {
                    icon:sofa,
                    icon_active:sofa_active,
                    name:'沙发系列',
                    series:"sofa",
                    id:"11",
                  },
                  {
                    icon:recliners,
                    icon_active:recliners_active,
                    name:'椅具系列',
                    id:"22",
                    series:"recliners"
                  },
                  {
                    icon:dining,
                    icon_active:dining_active,
                    name:'茶几系列',
                    id:"33",
                    series:"dining"
                  },
                  {
                    icon:bed,
                    icon_active:bed_active,
                    name:'床品系列',
                    series:"bed",
                    id:"44"
                  },
                  {
                    icon:light,
                    icon_active:light_active,
                    name:'灯具系列',
                    id:"55",
                    series:"light"
                  },
                ],
                productMaterial: [],
                product:{},
                productDetails:[],
                productDetailsImage:"",
                detislindex:0,
                productListDetails:[],
                showItemdata: { showItem: 0 },
            };
        },
        mounted() {
            let paths;
            if (sessionStorage.getItem("language") == 1) {
                paths = "";
            } else if (sessionStorage.getItem("language") == 2) {
                paths = "/En";
            } else {
                paths = "/It";
            }
            if (this._isMobile()) {
                this.$router.replace(
                    paths +
                    "/mbrandDetails?id=" +
                    this.$route.query.id
                );
            } else {
                this.$router.replace(
                    paths +
                    "/brandDetails?id=" +
                    this.$route.query.id
                );
            }
            this.getProductDetailsData(this.$route.query.id);
            window.addEventListener("scroll", this.productNavScroll);
            window.addEventListener("scroll", this.handleScroll);
            this.iscaseBaner = true;

        },
        methods: {

            mdshow(index) {
                this.productDetailsImage = this.productDetails[index];
                this.detislindex = index;
                if (this.ismdshow == 0) {
                    this.ishmqzsl = false;
                    this.ishmqzsr = true;
                } else if (this.ismdshow == this.productDetails.length - 1) {
                    this.ishmqzsl = true;
                    this.ishmqzsr = false;
                } else {
                    this.ishmqzsl = false;
                    this.ishmqzsr = false;
                }
            },
            hmqzsl() {
                if (this.ismdshow > 0) {
                    this.mdshow(--this.ismdshow);
                }
            },
            hmqzsr() {
                if (this.ismdshow < this.productDetails.length - 1) {
                    this.mdshow(++this.ismdshow);
                }
            },
            _isMobile() {
                let flag = navigator.userAgent.match(
                    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                );
                return flag;
            },

            handleScroll() {
                this.$nextTick(() => {
                    this.scroll =
                    document.documentElement.scrollTop || document.body.scrollTop;
                this.isbrandConcepttitle = true;
                this.issjmx = true;
                this.iskjmx = true;
                this.isbrandConcepttitle = true;
                if (this.$refs.kjmx.offsetTop < this.scroll + document.documentElement.clientHeight ) {
                    this.issjmxxx=true
                    //this.windwosHeight=this.scroll + document.documentElement.clientHeight;
                }
                if ( this.$refs.kjmx.offsetTop >this.scroll + document.documentElement.clientHeight - 700) {
                    this.issjmxxx = false
                }
                if (
                    this.scroll + document.documentElement.clientHeight>2700
                ) {
                    this.issjmxxx = false
                }
            });
            },
            productNavScroll() {
                this.scroll =
                    document.documentElement.scrollTop || document.body.scrollTop;
                if (this.scroll > 785) {
                    this.isproductnavTop = true;
                    this.isproductnavtopling = true;
                } else {
                    this.isproductnavTop = false;
                    this.isproductnavTopaa = false;
                    this.isproductnavTopbb = false;
                    this.isproductnavtopling = false;
                }
            },

            toProductDetailsData(produceId){
                window.scrollTo(790, 790);
                this.getProductDetailsData(produceId)
            },
            getProductDetailsData(produceId) {
                this.ismdshow=0;
                this.produceId=produceId;
                this.productInfoData=[];
                this.productDetails=[];
                this.displayImagesList=[];
                let data = { produceId:produceId}
                getProductLightDetails(data).then(res => {
                    this.product = res.lightProduce;
                this.productDetails=res.lightProduce.detailImages.split("|")
                this.displayImagesList=res.lightProduce.displayImages.split("|")
                this.productDetailsImage=this.productDetails[0]
                this.productInfoData.push({
                    name:'产品描述',
                    content:res.lightProduce.description
                })
                this.productInfoData.push({
                    name:'细节艺术',
                    content:res.lightProduce.details
                })
                this.productInfoData.push({
                    name:'购物须知',
                    content:res.lightProduce.deliveryPolicy
                })
               })
              this.getRandomProductlight();
            },
            getRandomProductlight() {
                getRandomProductlight().then(res => {
                this.randomProductList = res.lightProduce;
                })
            },
            kalajinxenter(index) {
                this.isdivshop = index;
            },
            kalajinxleave() {
                this.isdivshop = 999;
            },
            clicp() {
                this.iscp = true;
                this.isys = false;
            },
            cliys() {
                this.iscp = false;
                this.isys = true;
            },
            cfkjyscont(preindex, index) {
                if (this.lastclick != preindex) {
                    this.productMaterial[this.lastclick].showItem = 9999;
                }
                this.lastclick = preindex;
                this.productMaterial[preindex].showItem = index;
            },
            spance(index) {
                this.$router.push({path:'/homeProducts',query:{isindex:index}})
            },
            spanceInfo(index) {
                this.isInfoActiv = index;
            },
        },
        unmounted(){
            window.removeEventListener("scroll", this.productNavScroll);
            window.removeEventListener("scroll", this.handleScroll);
        }
    };
</script>

<style>

    .pinpailishi ul {
        width: 375px;
        height: 492px;
        overflow: hidden;
    }
    .pinpailishi ul li {
        display: flex;
    }
    .pinpailishi ul li img {
        width: 375px;
        height: 492px;
    }


    .kitchenDetails .founder img {
        width: 50px;
        height: 50px;
        transform: none;
    }
    .fixed {
        position: fixed; z-index: 1000;
        top: 23%; /* 或者其他你希望固定的位置 */
        left: 50%;
        width: 30%;
    }
    .kitchenDetails .founder .beijing {
        padding-bottom: 100px;
    }
    .kjdetails {
        width: 625px;
        margin: 0 auto;
        overflow: hidden;
    }
    .kjdetails > div {
        width: 40%;
        float: left;
        border: 1px solid #000;
        cursor: pointer;
        color: #000;
        padding: 2% 0;
    }
    .kjdetails > div.active {
        border: 1px solid #f08518;
        color: #f08518;
    }
    .kjdetails > div.kjdetailsright {
        float: right;
    }
    .kitchenDetails .founder .yishu {
        margin-top: 3%;
        margin-bottom: 2%;
    }
    .cfkjystitle {
        font-size: 30px;
        width: 1200px;
        margin: 0 auto;
        position: relative;
    }
    .cfkjystitle div {
        width: 450px;
        margin: 0 auto;
        background: #fff;
    }
    .cfkjystitle p {
        border-bottom: 1px solid #000;
        position: absolute;
        left: 0;
        width: 100%;
        top: 52%;
        z-index: -1565;
    }
    .cfkjcont {
        width: 1200px;
        margin: 35px auto 0;
    }
    .cfkjcont ul {
        width: 100%;
        display: flex;
        align-content: center;
    }
    .cfkjcont ul li {
        flex: 1;
        cursor: pointer;
    }
    .cfkjcont ul li.active img {
        border: 1px solid #f08518;
    }
    .cfkjcont ul li p {
        font-size: 12px;
        line-height: 2;
    }
    .cfkjcont ul li img {
        width: 50px;
        height: 50px;
    }
    .cfkjysbigimg {
        margin: 45px 0;
    }
    .cfkjysbigimg img {
        width: 100%;
    }
    .spacelunimg {
        width: 1200px;
        margin: 0 auto;
    }
    .spacelunimg img {
        width: 100%;
    }
    .chufangxbanner img {
        width: 100%;
    }
</style>
