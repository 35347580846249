<template>
    <div class="mhome">
        <div class="hometopswipt quanpin">
            <div class="pinpailishi">
                <div class="pplsimg">
                   <img src='https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/2.png'>
                </div>
            </div>
        </div>
      <div  v-for="(item, index) in productList" :key="index">
        <div class="hometopswipt">
            <div class="xiaotop"></div>
            <div class="pinpailishi">
                <div class="pplstext">
                    <div class="title">{{ item.name}}</div>
                    <p style="text-align:center;font-weight: 500">{{ item.advertise}} </p>
                </div>
            </div>
          <div class="wpq">
            <img style="width: 100%" :src="'https://www.vifa.cn'+item.mainImage" >
          </div>
          <div class="productcont">
            <router-link :to="{path:'/mbrandDetails',query:{id:item.id}}"><div style="border:1px solid gray" class="moreDivBut">了解更多</div></router-link>
          </div>
        </div>
      </div>

        <div class="subnav" >
            <ul>
                <li v-for="(item,index) in kjdzallData" :key="index" :class="{'avtive':isclick == index}" @click="spance(index)">
                    <img class="icon" :src=item.icon >
                    <img class="icon_active" :src=item.icon_active >
                    <p>{{item.name}}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import sofa from '@/common/image/sofa.png'
import sofa_active from '@/common/image/sofa_active.png'
import recliners from '@/common/image/recliners.png'
import recliners_active from '@/common/image/recliners_active.png'
import dining from '@/common/image/dining.png'
import dining_active from '@/common/image/dining_active.png'
import bed from '@/common/image/bed1.png'
import bed_active from '@/common/image/bed_active1.png'
import light from '@/common/image/light.png'
import light_active from '@/common/image/light_active.png'
import {getProductLight} from '@/api/api'
export default {
    data(){
        return{
            kjdzallData:[
              {
                icon:sofa,
                icon_active:sofa_active,
                name:'沙发系列',
                series:"sofa",
                id:"11",
              },
              {
                icon:recliners,
                icon_active:recliners_active,
                name:'椅具系列',
                id:"22",
                series:"recliners"
              },
              {
                icon:dining,
                icon_active:dining_active,
                name:'茶几系列',
                id:"33",
                series:"dining"
              },
              {
                icon:bed,
                icon_active:bed_active,
                name:'床品系列',
                series:"bed",
                id:"44"
              },
              {
                icon:light,
                icon_active:light_active,
                name:'灯具系列',
                id:"55",
                series:"light"
              },
            ],
            productCenterBanner:[],
            kjmarginLeft:-document.body.clientWidth,
            kjwidth:0,
            iskjshow:1,
            kjstartx:0,
            kjmovex:0,
            kjchangex:0,
            isclick:0,
            dajijuyrl:'http://christoph.cn',
            productList:[],
        }
    },
    mounted(){
        let paths;
        if (sessionStorage.getItem("language") == 1) {
          paths = "";
        } else if (sessionStorage.getItem("language") == 2) {
          paths = "/En";
        } else {
          paths = "/It";
        }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mHomeProducts');
        } else {
            this.$router.replace(paths+'/christophBrand');
        }
        this.getProductLightDetailsData("sofa")
        this.kjwidth = document.body.clientWidth * this.productCenterBanner.length

    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
      spance(index){
        this.isclick =index
        this.getProductLightDetailsData(this.kjdzallData[index].series);
      },
        getProductLightDetailsData(produceId){
          let data = { series:produceId}
          getProductLight(data).then(res => {
            this.productList = res.productList
          })
        },
        kjshow(index){
            this.kjchangex = this.kjstartx
            this.kjmarginLeft = -document.body.clientWidth*(index)
            console.log(this.kjmarginLeft,index)
        },
        kjtouchstart(e){
            this.kjstartx = e.touches[0].clientX
        },
        kjtouchmove(e){
            if(this.kjchangex == this.kjstartx){
                return
            }
            this.kjmovex = e.touches[0].clientX
            let kjleftslide = this.kjstartx - this.kjmovex
            console.log(this.iskjshow)
            if(kjleftslide > 30 && this.iskjshow < this.productCenterBanner.length - 1){
                this.kjshow(++this.iskjshow)
            }else if(kjleftslide < -30 && this.iskjshow > 0){
                this.kjshow(--this.iskjshow)
            }
        },
        todajiju(){
            window.open(this.dajijuyrl,'_blank')
        },
    },
}
</script>

<style scoped>
.moreDivBut {
    width: 26%;
    text-align: center;
    border: 1px solid gray;
    margin: 0 auto 4%;
    font-size: 12px;
    cursor: pointer;
    color: #000;
}
.homelogo{
    width: 100%;
}
.xiaotop{
    width: 1px;
    height: 34px;
    background: #f08518;
    margin: 35px auto 0;
}
.wpz{
    width: 670px;
    margin: 35px auto 35px;
}
.wpz img{
    width: 50%;
}
.wpq{
    width: 670px;
    margin: 5px auto 10px;
}
.wpq img,.quantu img{
    width: 100%;
}
.quantu{
    width: 750px;
    padding: 40px 0;
}
.pplstext{
    line-height: 1.8;
    width: 670px;
    margin: 0 auto;
}
.pinpailishi{
    position: relative;
}
.pinpailishi .title{
    font-size: 28px;
    padding: 5px 0;
    font-weight: 800;
}
.pinpailishi .subtitle{
    font-size: 30px;
}
.pinpailishi p{
    font-size: 20px;
    padding-bottom: 20px;
    text-align: justify;
}
.pplsimg{
    width: 100%;
    object-fit: cover;
}
.pplsimg img{
    width: 100%;
    object-fit: cover;
}
.pinpailishi ul{
    display: flex;
    margin-left: -247.5px;
}
.pinpailishi ul li{
    width: 375px;
    height: 492px;
    flex: 1;
    padding: 0 20px;
}
.pinpailishi ul li img{
    width: 375px;
    height: 492px;
}

.bottomtitle{
    width: 670px;
    margin: 0 auto;
    color: #f08518;
    font-size: 30px;
    line-height: 1.5;
    position: relative;
    position: absolute;
    left: 40px;
    top: 50%;
    margin-top: -56.25px;
}
.bottomtitle .showtitle p{
    text-align: center;
    color: #fff;
}
.bottomtitle .showtitle div{
    font-size: 45px;
    color: #fff;
}
.bottomtitle .toleft{
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 56.25px;
}
.bottomtitle .toright{
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 56.25px;
}
.bottomtitle .toleft span{
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    left: 10px;
    top: -9px;
}
.bottomtitle .toright span{
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    right: 10px;
    top: -9px;
}
.pinpailishiani-move{
    transition: all .3s;
}




.quanpin .bottomtitle{
    color: #fff;
}

.quanpin .pinpailishi ul{
    width: 2250px;
    margin: 0;
}
.quanpin .pinpailishi ul li{
    width: 750px;
    height: 447px;
    padding: 0;
}
.quanpin .pinpailishi ul li img{
    width: 750px;
    height: 447px;
}


.input{
    font-size: 26px;
    width: 670px;
    margin: 40px auto;
}
.input input{
    width: 100%;
    line-height: 2;
    border: 1px solid #4c413d;
    text-align: center;
}
.producttiel{
    background: #d2cdca;
    padding-top: 1px;
    padding-bottom: 40px;
}
.subnav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    /* background: #d2cdca; */
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
}
.subnav ul{
    width: 100%;
    display: flex;
    padding-top: 10px;
    flex-wrap: wrap;
    background: #000000cc;
    color: #fff;
}
.subnav ul li{
    flex: 1 137.5px;
    text-align: center;
    font-size: 20px;
    /* color: #fff; */
    line-height: 2;
    margin-bottom: 10px;
}
.subnav ul li.avtive{
    color: #f08518;
}
.subnav ul li p{}
.subnav ul li img{
    width: 61px;
    height: 45px;
    margin: 0 auto;
}
.subnav ul li.avtive .icon{
    display: none;
}
.subnav ul li.avtive .icon_active{
    display: block;
}
.subnav ul li .icon_active{
    display: none;
}
.subnav ul li .icon{
    display: block;
}
.productcont{
    padding: 20px 0;
    line-height: 2;
    width: 750px;
    text-align: center;
}
.productcont img{
    width: 100%;
}
.productcont p{
    font-size: 26px;
    line-height: 88px;
}
.productcont div{
    font-size: 24px;
    margin: 0 auto;
    line-height: 1;
    border: 1px solid #4c413d;
    line-height: 65px;
}
.twonav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    background: #fff;
    text-align: center;
}
.twonav ul{
    width: 100%;
    display: flex;
}
.twonav ul li{
    flex: 1;
}
.twonav ul li.active a{
    color: #f08518;
}

</style>
