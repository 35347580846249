<template>
    <div class="product christophBrand">
        <div  :class="{'jianbianbanner':iscaseBaner}" ref="caseBaner">
            <div class="spaceCustomization_img homeDecoration productkjdz" ref="homeDecoration">
                <div class="spaceCustomization">
                    <img src='https://vifawebsite.oss-cn-hangzhou.aliyuncs.com/1.jpg'>
                </div>
                <div class="homeDecorationMask" v-show="ishdMask" :style="{'height':hdmHeight}">
                    <div class="homeDecorationCenten">
                        <transition-group name="hdcm">
                            <ul>
<!--                                <li  v-for="(item, index) in kjdzData" :key="index" @click="kjdz(index)" :class="{'midl':midlIndex==index,'left':leftIndex == index,'right':rightIndex == index}">-->
<!--                                    <div>{{item.name}}</div>-->
<!--                                    <p>{{item.eName}}</p>-->
<!--                                </li>-->
                            </ul>
                        </transition-group>
                    </div>
                </div>
            </div>

        </div>
        <div class="productnav " style="background:black"  :class="{'productnavTop':isproductnavTop,'productnavTopaa':isproductnavTopaa,'productnavTopbb':isproductnavTopbb,'productnavtopling':isproductnavtopling}" ref="productnavTop">
            <div class="space dajijunav">
                <div :class="{spaceActiv:isSpaceActiv == index}" v-for="(item, index) in kjdzallData" :key="index" @click="spance(index)">
                    <img class="spaceimg" :src=item.icon alt="">
                    <img class="spaceimgActive" :src=item.icon_active alt="">
                    <p>{{item.name}}</p>
                </div>
            </div>
        </div>
        <div  :class="{'jianbianh':isbrandConcepttitle}" ref="brandConcepttitle">
          <div  v-for="(item, index) in productList" :key="index">
            <div class=" spaceCustomization">
<!--                  <img style="width: 100%" :src=" 'http://127.0.0.1:8080/vifacms'+ item.mainImage" >-->
                  <img style="width: 100%" :src="'https://www.vifa.cn'+item.mainImage" >
                  <div>
                    <div class="fontSize22" style="margin-top: 7%"><span style="text-align:center;font-weight: 500;">{{ item.name}} </span></div>
                    <router-link :to="{path:'/brandDetails',query:{id:item.id}}"><div style="margin-left: 1%"  class="moreBut">了解更多</div></router-link>
                    <div class="fontSize14" style="margin-top: 39%;width: 100%"><span style="text-align:center;font-weight: 500;">{{ item.advertise}} </span></div>
                  </div>

              </div>
          </div>

        </div>
    </div>
</template>

<script>
    import sofa from '@/common/image/sofa.png'
    import sofa_active from '@/common/image/sofa_active.png'
    import recliners from '@/common/image/recliners.png'
    import recliners_active from '@/common/image/recliners_active.png'
    import dining from '@/common/image/dining.png'
    import dining_active from '@/common/image/dining_active.png'
    import bed from '@/common/image/bed1.png'
    import bed_active from '@/common/image/bed_active1.png'
    import light from '@/common/image/light.png'
    import light_active from '@/common/image/light_active.png'

    import dajiajuhome1 from '@/common/image/dajiajuhome1.png'
    import dajiajuhome2 from '@/common/image/dajiajuhome2.png'
    import dajiajuhome3 from '@/common/image/dajiajuhome3.png'
    import {getProductLight } from "@/api/api";

    export default {
        data(){
            return{
              lightProduce:{},
              kjdzData:[
                {
                  name:'',eName:''
                },
                {
                  name:'',eName:''
                },
                {
                  name:'',eName:''
                },
              ],
                kjdzallData:[
                  {
                    icon:sofa,
                    icon_active:sofa_active,
                    name:'沙发系列',
                    series:"sofa",
                    id:"11",
                  },
                  {
                    icon:recliners,
                    icon_active:recliners_active,
                    name:'椅具系列',
                    id:"22",
                    series:"recliners"
                  },
                  {
                    icon:dining,
                    icon_active:dining_active,
                    name:'茶几系列',
                    id:"33",
                    series:"dining"
                  },
                  {
                    icon:bed,
                    icon_active:bed_active,
                    name:'床品系列',
                    series:"bed",
                    id:"44"
                  },
                  {
                    icon:light,
                    icon_active:light_active,
                    name:'灯具系列',
                    id:"55",
                    series:"light"
                  },
                ],
                productList:[],
                ishdMask:true,
                midlIndex:1,
                leftIndex:0,
                rightIndex:2,
                isSpaceCustomization:1,
                hdmHeight:920,
                kjdzallIndex:0,
                isSpaceActiv:0,
                isproductnavTop:false,
                productnavTopH:709,
                jiaxiangData:[
                    {
                        img:dajiajuhome1,
                    },
                    {
                        img:dajiajuhome2,
                    },
                    {
                        img:dajiajuhome3,
                    },
                ],
                newLfet:0,
                isactive:0,
                quanpin:document.body.clientWidth,
                quanpinl:99999,
                germanimgmta:0,
                germanimgmtb:0,
                germanimgmtc:0,
                germanimgopa:1,
                germanimgopb:1,
                germanimgopc:1,
                germanspanmta:60,
                germanspanmtb:120,
                germanspanmtc:60,
                germanspanopa:1,
                germanspanopb:1,
                germanspanopc:1,
                offtop:0,
                guoduheight:0,
                iscaseBaner:false,
                isbrandConcepttitle:true,
                issjmx:false,
                iskjmx:false,
                isleftno:false,
                isrightno:true,
                isproductnavTopaa:false,
                isproductnavTopbb:false,
                isproductnavtopling:false,
                dajijuyrl:'http://christoph.cn'
            }
        },
        mounted() {
            let paths;
            if (sessionStorage.getItem("language") == 1) {
                paths = "";
            } else if (sessionStorage.getItem("language") == 2) {
                paths = "/En";
            } else {
                paths = "/It";
            }
            if (this._isMobile()) {
              this.$router.replace(paths+'/mHomeProducts');
            } else {
              this.$router.replace(paths+'/homeProducts');
            }
            setInterval(() => {this.maskHeight()}, 1000)
            window.addEventListener("scroll", this.productNavScroll);
            window.addEventListener("scroll", this.handleScroll);
            this.iscaseBaner = true
            if (this.$route.query.isindex) {
                this.isSpaceActiv=this.$route.query.isindex
                this.getProductLightDetailsData(this.kjdzallData[this.$route.query.isindex].series)
            }else{
                this.getProductLightDetailsData("sofa")
            }
        },
        methods:{
            getProductLightDetailsData(produceId){
                let data = { series:produceId}
                getProductLight(data).then(res => {
                   this.productList = res.productList
                })
            },
            _isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                return flag;
            },
            handleScroll(){
                this.$nextTick(()=>{
                    this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
                if(this.isproductnavTop == true){
                    this.isproductnavTopaa = false
                    this.isproductnavTopbb = true
                }
                if(this.isproductnavTop == true){
                    this.isproductnavTopaa = true
                    this.isproductnavTopbb = false
                }
            })
            },
            maskHeight(){
                this.quanpinl = this.quanpin * this.jiaxiangData.length
            },
            defferScroll: function (event) {
                event.preventDefault()
            },
            germanBoxani(e){
                if (this.scroll > 3414 && this.scroll <3914) {
                    if (this.guoduheight > 1040) {
                        document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
                        this.guoduheight = 0
                        this.germanimgmtc = -520
                        this.germanimgopb = 0
                        this.germanimgopa = 0

                    }else{
                        this.guoduheight = this.guoduheight + e.deltaY
                        document.body.addEventListener("wheel", this.defferScroll, {passive: false});
                        if (this.guoduheight<=520) {
                            if (this.guoduheight>520) {
                                this.germanimgmtb = -520
                                this.germanimgopa = 0
                            }else{
                                this.germanimgmtb = -this.guoduheight
                                this.germanimgopa =  1 - this.guoduheight/520
                            }
                        }else{
                            if (this.guoduheight>1040) {
                                this.germanimgmtc = -520
                                this.germanimgopb = 0
                            }else{
                                let newheight = this.guoduheight - 520
                                this.germanimgmtc = -newheight
                                this.germanimgopb =  1 - newheight/520
                            }
                        }
                    }
                }else{
                    document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
                }
            },
            productNavScroll(){
                this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
                if (this.scroll > 710) {
                    this.isproductnavTop =true
                    this.isproductnavtopling = true
                }else{
                    this.isproductnavTop =false
                    this.isproductnavTopaa = false
                    this.isproductnavTopbb = false
                }
            },
            homeDM(){
                this.ishdMask = true
                this.hdcm = 0
            },
            homeDMout(){
                this.ishdMask = false

            },
            kjdz(index){
                if(this.midlIndex != index){
                    if (index == 0) {
                        this.$router.push('/productCenter')
                    }else if (index == 2) {
                        this.$router.push('/doorwallsystem')
                    }
                    this.isSpaceCustomization = index
                }
            },
            spance(index){
                this.kjdzallIndex =index
                this.isSpaceActiv = index
                this.getProductLightDetailsData(this.kjdzallData[index].series);
            },
            newswiLfet(){
                if (this.isactive>0) {
                    this.isactive = this.isactive-1
                    this.newLfet = -document.body.clientWidth * this.isactive
                    if (this.isactive == 0) {
                        this.isleftno = false
                        this.isrightno = true
                    }else{
                        this.isactive = true
                        this.isrightno = true
                    }
                }
            },
            newswiRight(){
                if (this.isactive < this.jiaxiangData.length - 1) {
                    this.isactive = this.isactive+1
                    this.newLfet = -document.body.clientWidth * this.isactive
                    if (this.isactive == this.jiaxiangData.length - 1) {
                        this.isleftno = true
                        this.isrightno = false
                    }else{
                        this.isleftno = true
                        this.isrightno = true
                    }
                }
            },
            xuanzhongle(index){
                this.isactive = index
                this.newLfet = -document.body.clientWidth * this.isactive
            },
            todajiju(){
                window.open(this.dajijuyrl,'_blank')
            },
        },
        unmounted(){
            window.removeEventListener("scroll", this.productNavScroll);
            window.removeEventListener("scroll", this.handleScroll);
        }
    }
</script>

<style>
    @import '../../../common/styles/productCenter.css';
    .homeDecorationCenten{
        position: relative;
    }

    .homeDecoration img {
        width: 100%;
    }
    .christophBrand .productnav{
        width: 100%;
        text-align: center;
    }
    .productnav .space{
        width: 1200px;
        margin: 0 auto;
        padding-top: 0;
    }
    .productnav .space div p{
        text-align: center;
        padding-bottom: 0;
    }
    .christophBrand .productnavTop{
        position: fixed;
        bottom: inherit;
        top: 0;
        left: 0;
        background: rgb(0 0 0 / 90%);
        z-index: 999;
    }
    .dajijunav div img{
        width: 35px;
    }
    .christophBrand .spaceCustomization {
      width: 100%;
      flex: 1 100%;
      cursor: pointer;
      position: relative;
    }
    .christophBrand .spaceCustomization img{
      margin: 0;
      transform: none;
      width: 100%;
    }

    .christophBrand .spaceCustomization div{
      position: absolute;
      width: 100%;
      left: 0%;
      top: 0%;
    }
    .christophBrand .spaceCustomization div .moreBut{
      position: absolute;
      width: 8%;
      left: 45%;
      margin-top: 35%;
      padding: 0.6% 0;
    }
    .margintopt{
        margin-top: 3%;
    }
    .christophBrand .zhontu img{
        width: 100%;
        transform: none;
        margin-top: 25px;
    }
    .christophBrand .enterpriseDynamics_box{
        width: 100%;
        height: 900px;
    }
    .christophBrand .enterpriseDynamics_news ul{
        width: 100%;
    }
    .christophBrand .enterpriseDynamics_news ul li{
        width: 100%;
        flex: 1 100%;
        margin: 0;
        padding: 0;
    }
    .christophBrand .enterpriseDynamics_news ul li img{
        width: 100%;
    }
    .germanBox{
        width: 100%;
        height: 520px;
        vertical-align: middle;
        text-align: center;
        margin: 0 auto;
        overflow: hidden;
    }
    .germanBox ul li{
        width: 1200px;
        margin: 0 auto;
        display: flex;
    }
    .germanBox ul li>div{
        width: 600px;
        height: 520px;
        flex: 1 600px;
    }
    .germanBox ul li>div img{
        width: 100%;
        height: 520px;
    }
    .germanBox ul li>div p{
        width: 100%;
        text-align: center;
        font-size: 50px;
        font-weight: 100;
        color: #4c413d;
        line-height: 60px;
        height: 180px;
        overflow: hidden;
        padding-top: 45px;
    }
    .germanBox ul li>div p.patopo{
        /* height: 210px; */
    }
    .germanBox ul li>div p span{
        display: block;
    }
    .germanimg-move{
        transition: all .5s;
    }
    .product .productkjdz ul li{
        padding-top: 120px;
        cursor: pointer;
    }
    .product .productkjdz ul li p{
        display: none;
    }
    .product .productkjdz ul li.midl p{
        display: block;
        margin-top: 10px;
    }
    .product .productkjdz ul li.midl{
        border-bottom: none;
        flex: 4;
        font-size: 2.5em;
        padding-top: 60px;
        overflow: initial;
    }
    .product .productkjdz ul li.midl>div{
        letter-spacing: 10px;
    }
    .product .productkjdz ul li.left{
        text-align: left;
    }
    .product .productkjdz ul li.right{
        text-align: right;
    }
    .product .homeDecorationCenten ul{
        padding-top: 150px;
    }
    .positre{
        position: relative;
    }
    .positre .switeName{
        position: absolute;
        left: 0;
        top: 375px;
    }
    .fontSize22{
      font-size: 22px;
    }
    .fontSize14{
      font-size: 14px;
    }
</style>
